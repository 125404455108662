import { store } from 'data/index'
import { sagaErrorFallback } from 'data/sagaFallback'
import { GlobalActionTypes } from 'data/types'
import {
  setUserIsReady,
  setFirebaseReady,
  setUserAction
} from 'data/user/actions'
import { all, fork, takeEvery } from 'redux-saga/effects'
import { firebaseAuthStateObserver } from 'services/firebase'
import * as Sentry from '@sentry/react'

const handleUserSignIn = (data: any) => {
  const email = data.email
  store.dispatch(setUserAction(email))
  Sentry.setUser({ email })
}

const handleUserSignOut = () => {
  store.dispatch(setUserAction(undefined))
}

const handleAuthReady = () => {
  store.dispatch(setUserIsReady())
}

const handleFirebaseReady = () => {
  store.dispatch(setFirebaseReady())
}

function* handleAppInit() {
  yield firebaseAuthStateObserver({
    handleUserSignIn,
    handleUserSignOut,
    handleAuthReady,
    handleFirebaseReady
  })
}

function* watchAppInit() {
  yield takeEvery(GlobalActionTypes.APP_INIT, sagaErrorFallback, handleAppInit)
}

export default function* root() {
  yield all([fork(watchAppInit)])
}
