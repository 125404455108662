import { createContext } from 'react'

interface ISideBarContext {
  isOpen: boolean
  hasOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setHasOpen: (hasOpen: boolean) => void
}

export const SideBarContext = createContext<ISideBarContext>({
  isOpen: true,
  hasOpen: false,
  setIsOpen: () => {},
  setHasOpen: () => {}
})
