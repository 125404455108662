import { interpolateColors } from 'services/helpers/colors'
import {
  TrendedMetricAdditiveEnum,
  TrendedMetricsResponseAdditive
} from './types'

export default (
  rawData: TrendedMetricsResponseAdditive[],
  rawSelectedMetric: TrendedMetricAdditiveEnum
): TrendedMetricsFormatData => {
  const output = {}
  let labels: string[] = []
  let key = ''
  switch (rawSelectedMetric) {
    case TrendedMetricAdditiveEnum.PURCHASE_UNITS:
      key = 'purchaseUnits'
      break
    case TrendedMetricAdditiveEnum.PURCHASE_VALUE:
      key = 'purchaseValue'
      break
    case TrendedMetricAdditiveEnum.SHARE_BY_VALUE:
      key = 'shareByValue'
      break
    case TrendedMetricAdditiveEnum.SHARE_BY_OCCASIONS:
      key = 'shareByValue'
      break
  }
  const labelsWithLowNumber = []
  for (const eachData of rawData) {
    labels.push(eachData.key)
    if (!(eachData.period in output)) {
      output[eachData.period] = {}
    }
    output[eachData.period] = {
      ...output[eachData.period],
      [eachData.key]: Math.round(eachData[key])
    }
    if (eachData.isLowNumber) labelsWithLowNumber.push(eachData.key)
  }

  const uniquePeriods = Object.keys(output).sort()
  labels = Array.from(new Set(labels))
  const pivotedOutput = {}

  for (const eachPeriod of uniquePeriods) {
    for (const label of labels) {
      const lowNumberCount = output[eachPeriod][`${label}_numberOfUsers`]
      const result = output[eachPeriod][label] || 0
      const currentData = pivotedOutput[label] || []
      currentData.push(result)
      pivotedOutput[label] = currentData
    }
  }
  const COLORS = interpolateColors(Object.entries(pivotedOutput).length)
  return {
    datasets: Object.entries<number[]>(pivotedOutput).reduce(
      (acc, [label, data], idx) => [
        ...acc,
        {
          label,
          data,
          backgroundColor: COLORS[idx],
          isLowNumber: labelsWithLowNumber.includes(label)
        }
      ],
      []
    ),
    labels: uniquePeriods
  }
}

interface TrendedMetricsFormatData {
  datasets: TrendedMetricsFormattedDataset[]
  labels: string[]
}

interface TrendedMetricsFormattedDataset {
  label?: string
  data?: number[]
  isLowNumber?: boolean
  backgroundColor?: string
}
