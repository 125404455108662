import axios from 'axios'
import config from 'config'
import firebase from 'firebase'
import pathToApi from 'services/helpers/pathToApi'

export const getReportsViaId = async (reportId?: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.post(
    url('/user/saved-reports'),
    { report_id: reportId },
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return data
}

export const putReport = async (rawData: any, reportId?: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.put(
    url('/user/saved-reports'),
    { report_id: reportId, data: rawData },
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return data
}

export const deleteReport = async (reportId: string) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.delete(url(`/user/saved-reports/${reportId}`), {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}
