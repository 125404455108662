import DropdownCheckbox from 'components/elements/DropdownCheckbox'
import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const chainsList = useSelector(ChartSelector.chainsList)
  const dispatch = useDispatch()
  return (
    <DropdownCheckbox
      onChange={i => dispatch(ChartActions.setSelectedChains(i))}
      value={chainsList.selected}
      data={chainsList.data}
      defaultNoneSelectedText="All Banners"
    />
  )
}
