import { PAGE_HEADER_HEIGHT } from 'components/page/Sidebar/style'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  transition: all 0.25s ease-in-out;
  padding: ${SPACE.medium}px;
  padding-top: ${SPACE.small}px;
  padding-left: ${SPACE.medium}px;
  background-color: ${COLOR.lightDarkBlue};
  min-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
`
export const LogoWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 15px;
  padding: 30px;
`
