import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { RsRefForwardingComponent, WithAsProps } from '../@types/common'
// import Search from '@rsuite/icons/legacy/Search';
import { useClassNames } from '../utils'

export interface SearchBarProps extends WithAsProps {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value: string, event: React.SyntheticEvent<HTMLElement>) => void;
}

const SearchBar: RsRefForwardingComponent<'div', SearchBarProps> = React.forwardRef(
  (props: SearchBarProps, ref) => {
    const {
      as: Component = 'div',
      classPrefix = 'picker-search-bar',
      value,
      children,
      className,
      placeholder,
      onChange,
      ...rest
    } = props
    const { withClassPrefix, merge, prefix } = useClassNames(classPrefix)
    const classes = merge(className, withClassPrefix())
    const handleChange = useCallback(
      (event: React.SyntheticEvent<HTMLElement>) => {
        onChange?.(get(event, 'target.value'), event)
      },
      [onChange]
    )
    return (
      <Component role="searchbox" {...rest} ref={ref} className={classes}>
        <input
          className={prefix('input')}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {/*<Search className={prefix('search-icon')} />*/}
        {children}
      </Component>
    )
  }
)

SearchBar.displayName = 'SearchBar'
SearchBar.propTypes = {
  as: PropTypes.elementType,
  classPrefix: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func
}

export default SearchBar
