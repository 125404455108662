import { PeriodEnum } from 'components/charts/CustomGroups'
import { AppReducerState } from 'data/reducers'
import { takeRight } from 'lodash'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import { createSelector } from 'reselect'

export const productsList = (state: AppReducerState) =>
  state.chart.chartParameter.products

export const chainsList = (state: AppReducerState) =>
  state.chart.chartParameter.chains

export const buyerGroupsList = (state: AppReducerState) =>
  state.chart.chartParameter.buyerGroups

export const customGroups = (state: AppReducerState) =>
  state.chart.chartCustomGroups.groups

export const relativePeriodList = (state: AppReducerState) =>
  state.chart.chartParameter.periods

export const isCustomGroupOpen = (state: AppReducerState) =>
  state.chart.chartParameter.isCustomGroupOpen

export const relativePeriodToAbsolute = (state: AppReducerState): string[] => {
  const availablePeriods = state.chart.chartParameter.periods.data
  const selectedPeriods = state.chart.chartParameter.periods.selected
  const RelativePeriodToAbsolute = {
    [PeriodEnum.LAST_4_WEEKS]: 1,
    [PeriodEnum.LAST_12_WEEKS]: 3,
    [PeriodEnum.LAST_26_WEEKS]: 6,
    [PeriodEnum.LAST_52_WEEKS]: 13
  }
  return takeRight<string>(
    availablePeriods,
    RelativePeriodToAbsolute[selectedPeriods.id]
  )
}

export const queryParamSelector = (state: AppReducerState) => {
  const { chains, products, buyerGroups } = state.chart.chartParameter
  const { groups } = state.chart.chartCustomGroups
  return {
    chains: chains.selected.map(i => i.id),
    products: cleanProductQuery(products.selected),
    buyerGroups: buyerGroups.selected
    // customGroups: Object.fromEntries(
    //   Object.entries(groups).map(([k, v]) => [k, cleanProductQuery(v)])
    // )
    // query from custom group not ready
  }
}

export const chartParameterSelector = (state: AppReducerState) =>
  state.chart.chartParameter

const chartCustomGroups = (state: AppReducerState) =>
  state.chart.chartCustomGroups.groups

export const chartQuerySelector = createSelector(
  chartParameterSelector,
  chartCustomGroups,
  relativePeriodToAbsolute,
  (parameters, groups, periods) => {
    const { chains, products, buyerGroups, isCustomGroupOpen } = parameters
    if (!isCustomGroupOpen) {
      return [
        {
          name: 'Default',
          chains: chains.selected.map(i => i.id),
          products: cleanProductQuery(products.selected),
          buyerGroups: buyerGroups.selected,
          periods
        }
      ]
    } else {
      return groups.map(group => ({
        name: group.name,
        chains: group.chains.map(i => i.id),
        products: cleanProductQuery(group.products),
        buyerGroups: group.buyerGroups,
        periods
      }))
    }
  }
)
