import { Loader } from '@progress/kendo-react-indicators'
import { Wrapper } from 'components/page/PageLoader/style'
import { isFirebaseReadySelector } from 'data/user/selectors'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isReadyApi } from 'services/api/requests/common/isReady'
import { SPACE } from 'services/styles'
import logo from 'static/logo-with-name.png'

export default () => {
  const isFirebaseReady = useSelector(isFirebaseReadySelector)
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    ;(async () => handleWarmUp())()
  }, [])

  const handleWarmUp = async () => {
    setIsReady(false)
    setTimeout(async () => {
      await isReadyApi()
      setIsReady(true)
    }, 200)
  }
  /* app is not ready until firebase and backend is ready */
  return (
    <AnimatePresence>
      {(!isReady || !isFirebaseReady) && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'anticipate', duration: 0.5 }}
        >
          <Wrapper>
            <img
              src={logo}
              style={{ height: 80, margin: SPACE.large }}
              alt="logo"
            />
            <Loader type="infinite-spinner" size="large" />
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
