import { GlobalActionTypes } from 'data/types'
import { UserActionTypes } from 'data/user/types'
import { createAction } from 'redux-actions'

export const appInitAction = () => createAction(GlobalActionTypes.APP_INIT)()

export const setUserAction = (email: string) =>
  createAction(UserActionTypes.SET_USER)({ email })

export const setUserIsReady = () =>
  createAction(UserActionTypes.SET_USER_IS_READY)()

export const setFirebaseReady = () =>
  createAction(UserActionTypes.SET_FIREBASE_READY)()
