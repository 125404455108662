import AnalyticsPage from 'pages/reports/main'
import ReportList from 'pages/reports/reports'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Routes from 'routes'

const ReportsNavigation = () => {
  return (
    <Switch>
      <Route exact path={Routes.reports.main()} component={AnalyticsPage} />
      {ReportList.map(i => (
        <Route exact path={i.path} component={i.component} key={i.path} />
      ))}
    </Switch>
  )
}

export default ReportsNavigation
