import { createContext, Ref } from 'react'
import { IStandardSelector } from 'types/standardSelector'

export const ReportContext = createContext<IReportUpdateContext>({
  isLoading: false,
  setIsLoading: () => {}
})

export interface IReportUpdateContext {
  isLoading: boolean
  setIsLoading: (setIsLoading: boolean) => void
}
