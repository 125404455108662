import { COLOR } from 'services/styles'

interface ProductTagResponse {
  cleanText: string
  color: string
  label: string
}

enum TagsType {
  CATEGORY = 'CATEGORY',
  MANUFACTURER = 'MANUFACTURER',
  BRAND = 'BRAND',
  SUB_BRAND = 'SUB_BRAND'
}

const TagToColor = {
  [TagsType.CATEGORY]: COLOR.emphasis,
  [TagsType.MANUFACTURER]: COLOR.danger,
  [TagsType.BRAND]: COLOR.warning,
  [TagsType.SUB_BRAND]: COLOR.success
}

const TagToText = {
  [TagsType.CATEGORY]: 'C',
  [TagsType.MANUFACTURER]: 'M',
  [TagsType.BRAND]: 'B',
  [TagsType.SUB_BRAND]: 'SB'
}
const TextToTag = {
  CAT: TagsType.CATEGORY,
  PB: TagsType.MANUFACTURER,
  SB: TagsType.BRAND,
  SSB: TagsType.SUB_BRAND
}

export const productToObjectType = (text: string): ProductTagResponse => {
  const categoryArray = text.split(' > ')
  if (categoryArray.length === 1) {
    if (text.includes('TOTAL CAT')) {
      const tagEnum = TextToTag.CAT
      return {
        cleanText: text.replace('CAT|', ''),
        color: TagToColor[tagEnum],
        label: TagToText[tagEnum]
      }
    }
    return {
      cleanText: text,
      color: null,
      label: null
    }
  }

  const category = categoryArray[categoryArray.length - 1]
  const tag = category.split('|')
  const tagEnum = TextToTag[tag[0].replace('TOTAL', '').trim()]
  return {
    cleanText: tag[1],
    color: TagToColor[tagEnum],
    label: TagToText[tagEnum]
  }
}
