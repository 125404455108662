import { PeriodEnum } from 'components/charts/CustomGroups'
import { DataProps } from 'components/elements/DropdownCheckbox'
import { ChartActionTypes, ChartParameter } from 'data/chart/types'
import produce from 'immer'
import { startCase } from 'lodash'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

const defaultPeriod = {
  id: PeriodEnum.LAST_12_WEEKS,
  text: startCase(PeriodEnum.LAST_12_WEEKS.toLowerCase())
}

const initialChartParameter: ChartParameter = {
  chains: { data: [], selected: [] },
  products: { data: [], selected: [] },
  buyerGroups: { data: [], selected: [] },
  periods: {
    data: [],
    relativePeriods: [],
    selected: defaultPeriod
  },
  isCustomGroupOpen: false
}

interface ChartCustomGroup {
  name: string
  products: string[]
  chains: DataProps[]
  buyerGroups: string[]
  periods: string[]
}

export interface ChartCustomGroups {
  groups: ChartCustomGroup[]
}

const initialChartCustomGroups: ChartCustomGroups = {
  groups: []
}

const chartParameter = handleActions<ChartParameter, any>(
  {
    [ChartActionTypes.LOAD_CHAINS_SUCCESSFUL]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.chains.data = payload
      }),
    [ChartActionTypes.SET_SELECTED_CHAINS]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.chains.selected = payload
      }),
    [ChartActionTypes.LOAD_PRODUCTS_SUCCESSFUL]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.products.data = payload
      }),
    [ChartActionTypes.SET_SELECTED_PRODUCTS]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.products.selected = payload
      }),
    [ChartActionTypes.LOAD_PERIODS_SUCCESSFUL]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.periods.data = payload
      }),
    [ChartActionTypes.LOAD_RELATIVE_PERIODS_SUCCESSFUL]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.periods.relativePeriods = payload
      }),
    [ChartActionTypes.SET_SELECTED_PERIOD]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.periods.selected = payload
      }),
    [ChartActionTypes.LOAD_BUYER_GROUPS_SUCCESSFUL]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.buyerGroups.data = payload
      }),
    [ChartActionTypes.SET_SELECTED_BUYER_GROUPS]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        draftState.buyerGroups.selected = payload
      }),
    [ChartActionTypes.RESET_CHART_PARAMETERS]: state =>
      produce<ChartParameter>(state, draftState => {
        draftState.buyerGroups.selected = []
        draftState.products.selected = []
        draftState.chains.selected = draftState.chains.data
        draftState.periods.selected = defaultPeriod
      }),
    [ChartActionTypes.TOGGLE_CUSTOM_GROUP_OPEN]: state =>
      produce<ChartParameter>(state, draftState => {
        draftState.isCustomGroupOpen = !draftState.isCustomGroupOpen
      }),
    [ChartActionTypes.REHYDRATE_CHART_PARAMETERS]: (state, { payload }) =>
      produce<ChartParameter>(state, draftState => {
        const { baseParameters } = payload
        const {
          buyerGroups,
          products,
          chains,
          period,
          isCustomGroupOpen
        } = baseParameters
        draftState.buyerGroups.selected = buyerGroups
        draftState.products.selected = products
        draftState.chains.selected = chains
        draftState.periods.selected = period
        draftState.isCustomGroupOpen = isCustomGroupOpen
      })
  },
  initialChartParameter
)

const chartCustomGroups = handleActions<ChartCustomGroups, any>(
  {
    [ChartActionTypes.ADD_CUSTOM_GROUP]: (state, { payload }) =>
      produce<ChartCustomGroups>(state, draftState => {
        draftState.groups = [...draftState.groups, payload]
      }),
    [ChartActionTypes.EDIT_CUSTOM_GROUP]: (state, { payload }) =>
      produce<ChartCustomGroups>(state, draftState => {
        const { prevName, group: updatedGroup } = payload
        draftState.groups = draftState.groups.map(group =>
          group.name === prevName ? updatedGroup : group
        )
      }),
    [ChartActionTypes.DELETE_CUSTOM_GROUP]: (state, { payload }) =>
      produce<ChartCustomGroups>(state, draftState => {
        const { name } = payload
        draftState.groups = draftState.groups.filter(
          group => group.name !== name
        )
      }),
    [ChartActionTypes.REHYDRATE_CHART_PARAMETERS]: (state, { payload }) =>
      produce<ChartCustomGroups>(state, draftState => {
        const { customGroups } = payload
        draftState.groups = customGroups
      })
  },
  initialChartCustomGroups
)

const reducer = combineReducers<CombinedChartState>({
  chartParameter,
  chartCustomGroups: chartCustomGroups
})

export interface CombinedChartState {
  readonly chartParameter: ChartParameter
  readonly chartCustomGroups: ChartCustomGroups
}

export default reducer
