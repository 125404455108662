export const dateToWeekEnding = {
  2001: '2020-01-25',
  2002: '2020-02-22',
  2003: '2020-03-21',
  2004: '2020-04-18',
  2005: '2020-05-16',
  2006: '2020-06-13',
  2007: '2020-07-11',
  2008: '2020-08-08',
  2009: '2020-09-05',
  2010: '2020-10-03',
  2011: '2020-10-31',
  2012: '2020-11-28',
  2013: '2020-12-26',
  2101: '2021-01-23',
  2102: '2021-02-20',
  2103: '2021-03-20',
  2104: '2021-04-17',
  2115: '2021-05-15',
  2106: '2021-06-12',
  2117: '2021-07-10',
  2118: '2021-08-07',
  2119: '2021-09-04',
  2110: '2021-10-02',
  2111: '2021-10-30',
  2112: '2021-11-27',
  2113: '2021-12-25'
}

export const periodListToText = (input: string[]) => input.map(periodToText)

export const periodToText = (text: string) =>
  `4 Wks ending\n ${dateToWeekEnding[text]}`
