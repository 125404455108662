import config from 'config'

import firebase from 'firebase/app'

require('firebase/auth')
const { firebaseConfig } = config

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const firebaseLogin = async (email, password) => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

export const firebaseLogout = async () => firebase.auth().signOut()

export const firebaseAuthStateObserver = ({
  handleUserSignIn,
  handleUserSignOut,
  handleAuthReady,
  handleFirebaseReady
}: AuthStateObserverProps) => {
  firebase.auth().onAuthStateChanged(function (user) {
    handleFirebaseReady()
    if (user) {
      handleUserSignIn(user)
      handleAuthReady()
    } else {
      handleUserSignOut()
    }
  })
}

interface AuthStateObserverProps {
  handleUserSignIn: (user: any) => void
  handleUserSignOut: () => void
  handleAuthReady: () => void
  handleFirebaseReady: () => void
}

export const getIdToken = async () => {
  setTimeout(() => {
    const result = firebase.auth().currentUser.getIdToken()
  }, 1000)
}
