import { SPACE } from 'services/styles'
import styled from 'styled-components'

export default styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${SPACE.medium}px;
`
