import auth from './auth'
import reports from './reports'

export default {
  dashboard: () => '/',
  reports,
  account: () => '/account',
  settings: () => '/settings',
  auth
}
