const REPORTS_BASE_URL = '/reports/explore'
export default {
  main: () => `${REPORTS_BASE_URL}`,
  dow: () => `${REPORTS_BASE_URL}/dow`,
  tod: () => `${REPORTS_BASE_URL}/tod`,
  basketSize: () => `${REPORTS_BASE_URL}/basket-size`,
  penetration: () => `${REPORTS_BASE_URL}/penetration`,
  tableReport: () => `${REPORTS_BASE_URL}/table-report`,
  trendedMetricsAdditive: () => `${REPORTS_BASE_URL}/trended-metrics-additive`,
  trendedMetricsNonAdditive: () =>
    `${REPORTS_BASE_URL}/trended-metrics-non-additive`,
  purchaseDrivers: () => `${REPORTS_BASE_URL}/purchase-drivers`,
  demographics: () => `${REPORTS_BASE_URL}/demographics`
}
