import {
  CLOSED_SUBMENU_WIDTH,
  PAGE_HEADER_HEIGHT,
  ROOT_MENU_WIDTH,
  SUBMENU_WIDTH
} from 'components/page/Sidebar/style'
import { LogoWrapper, Wrapper } from 'components/page/StandardPage/style'
import { SideBarContext } from 'navigation/sidebarContext'
import React, { FC, useContext, useEffect } from 'react'
import { Container } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import logo from 'static/logo-with-name.png'

const StandardPage: FC<IStandardPage> = props => {
  const { headerText, pageTitle } = props
  useEffect(() => {
    document.title = pageTitle || 'Nielsen | U Group'
  }, [pageTitle])
  const { isOpen, hasOpen } = useContext(SideBarContext)
  let sidebarWidth = ROOT_MENU_WIDTH
  if (hasOpen) {
    if (isOpen) sidebarWidth = ROOT_MENU_WIDTH + SUBMENU_WIDTH
    else sidebarWidth = ROOT_MENU_WIDTH + CLOSED_SUBMENU_WIDTH
  }
  return (
    <Wrapper
      style={{
        top: PAGE_HEADER_HEIGHT,
        marginLeft: sidebarWidth,
        width: `calc(100% - ${sidebarWidth}px)`
      }}
    >
      {headerText && (
        <h3 style={{ marginBottom: SPACE.small, color: COLOR.white }}>
          {headerText}
        </h3>
      )}
      <Container style={{ marginBottom: SPACE.large }}>
        {props.children}
      </Container>
      <LogoWrapper>
        <img src={logo} alt="logo" style={{ height: 40 }} />
      </LogoWrapper>
    </Wrapper>
  )
}

interface IStandardPage {
  headerText?: string
  pageTitle: string
}

export default StandardPage
