import ReportList from 'pages/reports/reports'
import React from 'react'
import { FaChartArea, FaCog, FaHome } from 'react-icons/fa'
import routes from 'routes'

const MenuItems: IMenuItem[] = [
  {
    icon: FaHome,
    title: 'Dashboard',
    path: routes.dashboard()
  },
  {
    icon: FaChartArea,
    title: 'Analysis',
    items: ReportList.map(i => ({ title: i.title, path: i.path })),
    path: routes.reports.main()
  }
  // {
  //   icon: FaCog,
  //   title: 'Settings',
  //   path: routes.settings()
  // }
]

export interface IMenuItem {
  icon?: any
  title?: string
  path: string
  items?: IMenuItem[]
}

export default MenuItems
