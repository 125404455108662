import { setUserAction } from 'data/user/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown } from 'rsuite'
import { firebaseLogout } from 'services/firebase'
import { SPACE } from 'services/styles'
import logo from 'static/logo-with-name.png'
import { PageHeaderWrapper, UserIconText, UserIconWrapper } from './style'

export default () => {
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const handleLogout = async () => {
    dispatch(setUserAction(undefined))
    return firebaseLogout()
  }
  return (
    <PageHeaderWrapper>
      <div>
        <img
          src={logo}
          style={{ height: 35, marginLeft: SPACE.medium }}
          alt="logo"
        />
        {/* <Input */}
        {/*  placeholder={'Search...'} */}
        {/*  style={{ */}
        {/*    backgroundColor: COLOR.lightDarkBlue, */}
        {/*    color: COLOR.white, */}
        {/*    padding: SPACE.small * 1.5, */}
        {/*    width: 500, */}
        {/*    fontSize: 17, */}
        {/*    marginLeft: SPACE.medium, */}
        {/*    borderRadius: BORDER_RADIUS.standard * 3 */}
        {/*  }} */}
        {/* /> */}
      </div>
      <Dropdown
        noCaret
        trigger="hover"
        icon={<UserIcon />}
        placement="bottomEnd"
        style={{ marginRight: SPACE.tiny }}
      >
        {/* <Dropdown.Item */}
        {/*  onClick={handleLogout} */}
        {/*  style={{ zIndex: 99999 }} */}
        {/*  renderItem={() => ( */}
        {/*    <DropdownItem> */}
        {/*      <FaSignOutAlt /> */}
        {/*      Sign out */}
        {/*    </DropdownItem> */}
        {/*  )} */}
        {/* /> */}
      </Dropdown>
    </PageHeaderWrapper>
  )
}

const UserIcon = () => (
  <UserIconWrapper>
    <UserIconText>U</UserIconText>
  </UserIconWrapper>
)
