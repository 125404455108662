export const separator = '[SEP]'
export const paramsToPeriodsMatrix = (params: any[]): any[] => {
  const periods = params.reduce(
    (acc, param) => [...acc, ...param.periods],
    []
  ) as string[]
  const uniquePeriods = Array.from(new Set(periods))

  // generate queries for all possible group/period combinations
  return params.reduce((queryAcc, eachQuery) => {
    const { name, chains, buyerGroups, products } = eachQuery
    const queryForPeriod = uniquePeriods.reduce((acc, eachPeriod) => {
      return [
        ...acc,
        {
          name: `${name}${separator}${eachPeriod}`,
          chains,
          buyerGroups,
          periods: [eachPeriod],
          products
        }
      ]
    }, [])
    return [...queryAcc, ...queryForPeriod]
  }, [])
}
