export const EnumChainToCanonical = {
  DAN_MURPHYS: "Dan Murphy's",
  BWS: 'BWS',
  LIQUORLAND: 'Liquorland',
  FIRST_CHOICE: 'First Choice',
  'DAN MURPHYS': "Dan Murphy's",
  LIQOURLAND: 'Liquorland',
  'FIRST CHOICE': 'First Choice',
  'DAN MURPHYS (online)': "Dan Murphy's",
  'BWS (online)': 'BWS',
  'LIQUORLAND (online)': 'Liquorland',
  FIRST_CHOICE_LIQUOR: 'First Choice'
}
