import { Text } from '@progress/kendo-drawing'
import { Point } from '@progress/kendo-drawing/geometry'
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { startCase } from 'lodash'
import { ChartTypes } from 'pages/reports/reports/demographics/index'
import { GraphTitle } from 'pages/reports/reports/demographics/style'
import {
  householdSize,
  incomeMap
} from 'pages/reports/reports/demographics/types'
import React from 'react'
import { Col } from 'rsuite'
import { COLOR } from 'services/styles'

const renderVisual = (e: any) => {
  const rect = e.rect
  const { x, y } = rect.origin
  const { width, height } = rect.size
  let newText = parseInt(e.dataItem.indexedRaw).toString()
  if (newText.length === 2) newText = ` ${newText}`
  if (newText.length === 1) newText = `  ${newText}`
  return new Text(
    newText,
    new Point(Math.round(x + width / 4), Math.round(y + height / 3)),
    { fill: { color: COLOR.white }, font: 'bold 1rem DIN' }
  )
}

interface ChartGroupProp {
  shareBy: any
  selectedChartType: ChartTypes
  data: object
  hiddenIndices: object
  setHiddenIndices: (data: any) => void
  shouldShowIndex: boolean
}

export const ChartGroup = ({
  shareBy,
  selectedChartType,
  data,
  hiddenIndices,
  setHiddenIndices,
  shouldShowIndex
}: ChartGroupProp) => {
  const key = `${shareBy.id}${startCase(selectedChartType).replace(/\s/g, '')}`
  const graphTitle = startCase(selectedChartType).replace(/\s/g, ' ')
  if (key in data) {
    const resultPayload = data[key] as any
    const targetList = []
    const benchmarkList = []
    const thisGraphHidden = hiddenIndices[selectedChartType]
    for (const eachRow of resultPayload) {
      let keyName = eachRow[selectedChartType].toString()
      if (selectedChartType === 'income') {
        keyName = incomeMap[keyName]
      }
      if (selectedChartType === 'householdSize') {
        keyName = householdSize[keyName]
      }
      targetList.push({
        data: [eachRow[`${shareBy.id}`]],
        label: keyName,
        visible: !thisGraphHidden.includes(keyName)
      })
      benchmarkList.push({
        data: [
          {
            benchmarkRaw: eachRow[`${shareBy.id}Benchmark`],
            indexedRaw: eachRow.indexed
          }
        ],
        label: keyName,
        visible: !thisGraphHidden.includes(keyName)
      })
    }
    const handleLegendClick = event => {
      const itemResult = targetList[event.seriesIndex]
      if (thisGraphHidden.includes(itemResult.label)) {
        setHiddenIndices({
          ...hiddenIndices,
          [selectedChartType]: thisGraphHidden.filter(
            i => i !== itemResult.label
          )
        })
      } else {
        setHiddenIndices({
          ...hiddenIndices,
          [selectedChartType]: [...thisGraphHidden, itemResult.label]
        })
      }
    }
    return (
      <div>
        <GraphTitle>{graphTitle}</GraphTitle>
        <Col lg={13} md={14} sm={14} style={{ minWidth: 190 }}>
          <Chart transitions={false} onLegendItemClick={handleLegendClick}>
            <ChartLegend position="left" reverse={true} />
            <ChartArea background={'transparent'} />
            <ChartTooltip shared={true} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem categories={['Selected Group']}>
                <ChartCategoryAxisTitle text="Group" visible={false} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
              {targetList.map(i => (
                <ChartSeriesItem
                  border={{ width: 0 }}
                  type="column"
                  data={i.data}
                  stack={{ type: '100%' }}
                  name={i.label}
                  visible={i.visible}
                />
              ))}
            </ChartSeries>
          </Chart>
        </Col>
        <Col lg={11} md={10} sm={10} style={{ maxWidth: 200 }}>
          <Chart transitions={false}>
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{ text: 'Percent' }}
                min={0}
                max={1}
                visible={false}
              />
            </ChartValueAxis>
            <ChartArea background={'transparent'} />
            <ChartLegend visible={false} />
            <ChartTooltip visible={true} shared={true} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={['Indexed against of population']}
              >
                <ChartCategoryAxisTitle text="Group" visible={false} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
              {benchmarkList.map(i =>
                shouldShowIndex ? (
                  <ChartSeriesItem
                    type="column"
                    color={'transparent'}
                    visual={renderVisual}
                    data={i.data}
                    field="benchmarkRaw"
                    stack={{ type: '100%' }}
                    name={i.label}
                    visible={i.visible}
                  />
                ) : (
                  <ChartSeriesItem
                    type="column"
                    data={i.data}
                    field="benchmarkRaw"
                    stack={{ type: '100%' }}
                    name={i.label}
                    visible={i.visible}
                  />
                )
              )}
            </ChartSeries>
          </Chart>
        </Col>
      </div>
    )
  }
  return null
}
