import {
  ChartValueAxis,
  ChartValueAxisItem,
  Sparkline
} from '@progress/kendo-react-charts'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import DataGridSortable from 'components/charts/DataGridSortable'
import {
  householdSize,
  incomeMap
} from 'pages/reports/reports/demographics/types'
import React from 'react'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { COLOR } from 'services/styles'

const RenderDataTable = ({ data, dataTableRef }) => {
  let excelOutput = []
  for (const [, value] of Object.entries<object[]>(data)) {
    const fixedValue = value.map(renameKeysInResult)
    excelOutput = [...excelOutput, ...fixedValue]
  }

  return (
    <ExcelExport data={excelOutput} ref={dataTableRef}>
      <DataGridSortable data={excelOutput}>
        <DataColumn field="metric" title="Metric" />
        <DataColumn field="group" title="Group" cell={WarningCell} />
        <DataColumn field="category" title="Category" />
        <DataColumn field="benchmark" title="Benchmark" format="{0:n0}%" />
        <DataColumn field="target" title="Selected Group" format="{0:n0}%" />
        <DataColumn field="indexed" title="Indexed Value" format="{0:n0}" />
        <DataColumn field="indexed" title="Indexed" cell={BulletCell} />
      </DataGridSortable>
    </ExcelExport>
  )
}

const WarningCell = props => <RenderWarningCell {...props} />
const BulletCell = props => <RenderBulletCell {...props} />

const renameKeysInResult = (data: object) => {
  const output: any = {}
  for (const [key, value] of Object.entries(data)) {
    if (['ageGroup', 'householdSize', 'income'].includes(key)) {
      if (key.includes('ageGroup')) {
        output.group = 'Age Group'
        output.category = value
      } else if (key.includes('householdSize')) {
        output.group = 'Household Size'
        output.category = householdSize[value]
      } else if (key.includes('income')) {
        output.group = 'Income'
        output.category = incomeMap[value]
      }
    }
    if (['totalUnits', 'totalSpend', 'totalBuyers'].includes(key)) {
      output.target = value
      if (key.includes('Units')) {
        output.metric = 'Units'
      } else if (key.includes('Spend')) {
        output.metric = 'Spend'
      } else if (key.includes('Buyers')) {
        output.metric = 'Buyers'
      }
    }
    if (key.includes('benchmark') || key.includes('Benchmark')) {
      output.benchmark = value
    }
    if (key === 'indexed') {
      output.indexed = value
    }
    if (key === 'unweightedUserCount') {
      output.unweightedLineCount = value
    }
    if (key === 'isLowNumber') {
      output.isLowNumber = value
    }
  }
  return output
}

const RenderWarningCell = props => (
  <div>
    {changeTextIfLowNumber(
      props.dataItem[props.field],
      props.dataItem.isLowNumber
    )}
  </div>
)

const RenderBulletCell = props => {
  const value = props.dataItem[props.field]
  return (
    <div style={{ height: 35, display: 'flex', alignItems: 'center' }}>
      <Sparkline
        data={[[value, 100]]}
        type={'bullet'}
        seriesColors={['transparent']}
      >
        <ChartValueAxis>
          <ChartValueAxisItem
            majorGridLines={{ visible: false }}
            minorTicks={{ visible: false }}
            min={0}
            max={200}
            plotBands={[
              {
                from: 0,
                to: 200,
                color: 'rgba(255,255,255,0.1)'
              },
              {
                from: 0,
                to: value < 100 ? value : 100,
                color: 'rgba(255,255,255,0.4)'
              },
              {
                from: 100,
                to: value < 100 ? 100 : value < 200 ? value : 200,
                color: COLOR.seriesC
              }
            ]}
          />
        </ChartValueAxis>
      </Sparkline>
    </div>
  )
}

const MemoizedTable = React.memo(RenderDataTable)
export default MemoizedTable
