import { DataProps } from 'components/elements/DropdownCheckbox'
import { ChartActionTypes } from 'data/chart/types'
import { createAction } from 'redux-actions'

export const loadProductsSuccessful = (products: string[]) =>
  createAction(ChartActionTypes.LOAD_PRODUCTS_SUCCESSFUL)(products)

export const setSelectedProducts = (products: string[]) =>
  createAction(ChartActionTypes.SET_SELECTED_PRODUCTS)(products)

export const loadChainSuccessful = (chains: string[]) =>
  createAction(ChartActionTypes.LOAD_CHAINS_SUCCESSFUL)(chains)

export const setSelectedChains = (chains: DataProps[]) =>
  createAction(ChartActionTypes.SET_SELECTED_CHAINS)(chains)

export const loadPeriodSuccessful = (periods: DataProps[]) =>
  createAction(ChartActionTypes.LOAD_PERIODS_SUCCESSFUL)(periods)

export const loadRelativePeriodSuccessful = (periods: DataProps[]) =>
  createAction(ChartActionTypes.LOAD_RELATIVE_PERIODS_SUCCESSFUL)(periods)

export const setSelectedPeriod = (period: DataProps) =>
  createAction(ChartActionTypes.SET_SELECTED_PERIOD)(period)

export const loadBuyerGroupsSuccessful = (buyerGroups: string[]) =>
  createAction(ChartActionTypes.LOAD_BUYER_GROUPS_SUCCESSFUL)(buyerGroups)

export const setSelectedBuyerGroups = (buyerGroups: string[]) =>
  createAction(ChartActionTypes.SET_SELECTED_BUYER_GROUPS)(buyerGroups)

export const addCustomGroup = (newGroup: object) =>
  createAction(ChartActionTypes.ADD_CUSTOM_GROUP)(newGroup)

export const editCustomGroup = (prevName: string, group: object) =>
  createAction(ChartActionTypes.EDIT_CUSTOM_GROUP)({ prevName, group })

export const deleteCustomGroup = (name: string) =>
  createAction(ChartActionTypes.DELETE_CUSTOM_GROUP)({ name })

export const rehydrateChartParameters = (data: any) =>
  createAction(ChartActionTypes.REHYDRATE_CHART_PARAMETERS)(data)

export const resetChartParameters = () =>
  createAction(ChartActionTypes.RESET_CHART_PARAMETERS)()

export const toggleIsCustomGroupOpen = () =>
  createAction(ChartActionTypes.TOGGLE_CUSTOM_GROUP_OPEN)()
