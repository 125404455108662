const MINIMUM_NUMBER_OF_USERS = 100

export const changeTextIfLowNumber = (
  text: string,
  isNumberLow: boolean
): string => {
  if (isNumberLow) return `${text}*`
  return text
}

export const isLowNumber = (input: number) => input < MINIMUM_NUMBER_OF_USERS
