import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from 'config'

export const initSentry = (history: History) => {
  if (config.sentryDsn) {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      tracesSampleRate: 1.0,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            successMessage:
              'Your feedback has been sent. Our team will look into it shortly'
          })
        }
        return event
      }
    })
  }
}
