import MenuItems, { IMenuItem } from 'components/page/Sidebar/MenuItems'
import {
  CloseButton,
  CLOSED_SUBMENU_WIDTH,
  defaultMenuButtonProps,
  SUBMENU_WIDTH,
  SubMenuTitleRow,
  SubMenuTitleText,
  SubMenuWrapper
} from 'components/page/Sidebar/style'
import { SideBarContext } from 'navigation/sidebarContext'
import React, { useContext, useEffect } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { SPACE } from 'services/styles'

export default () => {
  const { setIsOpen, isOpen, setHasOpen } = useContext(SideBarContext)
  const location = useLocation()
  const renderComponent = () => {
    const pathname = location.pathname
    for (const item of MenuItems) {
      const childPath = item.items
        ? item.items.some(i => i.path === pathname)
        : false
      if (item.path === pathname || childPath) {
        return item.items
          ? renderSubMenu(item, pathname, isOpen, () => setIsOpen(!isOpen))
          : null
      }
    }
    return null
  }
  const renderResult = renderComponent()
  useEffect(() => {
    setHasOpen(renderResult !== null)
  })
  return renderResult
}

const renderSubMenu = (
  item: IMenuItem,
  currentPath: string,
  isOpen: boolean,
  setIsOpen: any
) => {
  let currentActivePath = ''
  for (const eachChild of item.items) {
    if (eachChild.path === currentPath) currentActivePath = eachChild.title
  }
  const Icon = item.icon
  return (
    <SubMenuWrapper
      style={{ width: isOpen ? SUBMENU_WIDTH : CLOSED_SUBMENU_WIDTH }}
    >
      <CloseButton onClick={setIsOpen}>
        {isOpen ? <FaAngleLeft size={16} /> : <FaAngleRight size={16} />}
      </CloseButton>
      {isOpen ? (
        <>
          <SubMenuTitleRow>
            <Icon
              {...defaultMenuButtonProps}
              style={{
                fontSize: 20,
                marginLeft: SPACE.small,
                marginRight: SPACE.small
              }}
            />
            <SubMenuTitleText>{item.title}</SubMenuTitleText>
          </SubMenuTitleRow>
          <Nav vertical style={{ marginTop: SPACE.small }}>
            {item.items.map(i => (
              <Link to={i.path} style={{ textDecoration: 'none' }}>
                <Nav.Item
                  active={currentActivePath === i.title}
                  eventKey={i.title}
                >
                  {i.title}
                </Nav.Item>
              </Link>
            ))}
          </Nav>
        </>
      ) : null}
    </SubMenuWrapper>
  )
}
