import { exportPDF } from '@progress/kendo-drawing'
import { Rect } from '@progress/kendo-drawing/geometry'
import { saveAs } from '@progress/kendo-file-saver'
import { exportVisual } from '@progress/kendo-react-charts'
import {
  CreateNewReportDialog,
  DeleteReportDialog,
  RenameReportDialog
} from 'components/dialogs'
import { ReportContext } from 'components/page/ReportPage/context'
import ReportMetadata from 'components/page/ReportPage/ReportHeader'
import { IReportMetadataState } from 'components/page/ReportPage/types'
import StandardPage from 'components/page/StandardPage'
import { ChartActions } from 'data/actions'
import { hideDialog, showDialog } from 'data/dialog/actions'
import { ChartSelector } from 'data/selectors'
import html2canvas from 'html2canvas'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  deleteReport,
  getReportsViaId,
  putReport
} from 'services/api/requests/reports/common'
import { COLOR } from 'services/styles'

const mm = val => val * 2.8347
const PAGE_RECT = new Rect([0, 0], [mm(210 - 20), mm(297 - 20)])
const ReportPage: FC<IReportPage> = props => {
  const location = useLocation()
  const history = useHistory()
  const chartRef = useRef()
  const tableRef = useRef()
  const urlParams = new URLSearchParams(location.search)
  const queryReportId = urlParams.get('report_id')
  const isSaved = !!queryReportId
  const { title } = props
  const [dataSource, setDataSource] = useState([{ '': '' }])
  const route = location.pathname

  const initialState = {
    route: location.pathname,
    canSave: !isSaved,
    reportId: queryReportId
  }

  const [reportMetadata, setReportMetadata] = useState<IReportMetadataState>(
    initialState
  )

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const customGroups = useSelector(ChartSelector.customGroups)
  const chartParameters = useSelector(ChartSelector.chartParameterSelector)

  useEffect(() => {
    if (queryReportId) {
      ;(async () => handleLoadReport())()
    }
  }, [queryReportId])

  const resetState = () => {
    setReportMetadata(initialState)
    setDataSource([{ '': '' }])
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 200)
  }

  const handleLoadReport = async () => {
    setIsLoading(true)
    const responses = await getReportsViaId(queryReportId)
    const response = responses[0]
    dispatch(ChartActions.rehydrateChartParameters(response.parameters))
    setReportMetadata(response)
    setIsLoading(false)
  }

  const handleSaveClick = async () => {
    //  @ts-ignore
    if (queryReportId) return handleSaveNewReport()
    else return handleSaveAsNew()
  }

  const handleSaveAsNew = () =>
    handleDispatchDialog(
      <CreateNewReportDialog
        onComplete={(name, description) => {
          handleHideDispatchDialog()
          return handleSaveNewReport({ name, description })
        }}
        onCancel={handleHideDispatchDialog}
      />
    )

  const handleRenameClick = () =>
    handleDispatchDialog(
      <RenameReportDialog
        onComplete={(name, description) => {
          handleHideDispatchDialog()
          return handleSaveNewReport({
            reportId: queryReportId,
            name,
            description
          })
        }}
        onCancel={handleHideDispatchDialog}
        defaultName={reportMetadata.name}
        defaultDescription={reportMetadata.description}
      />
    )

  const handleSaveNewReport = async ({
    reportId,
    name,
    description
  }: {
    reportId?: string
    name: string
    description: string
  }) => {
    setIsLoading(true)
    // const image = await exportImage(exportVisual(reportMetadata.ref))
    const tempImage = await html2canvas(chartRef.current, {
      backgroundColor: COLOR.darkBlue
    })
    const image = tempImage.toDataURL('image/png', 0.5)
    const reportState = {
      name,
      description,
      route,
      originalUrl: route,
      originalReportName: title,
      image,
      parameters: {
        customGroups,
        baseParameters: {
          buyerGroups: chartParameters.buyerGroups.selected,
          products: chartParameters.products.selected,
          chains: chartParameters.chains.selected,
          period: chartParameters.periods.selected,
          isCustomGroupOpen: chartParameters.isCustomGroupOpen
        }
      }
    }
    const response = await putReport(reportState, reportId)
    const constNewReportId = response.sk.replace('REPORT#', '')
    history.push(location.pathname + '?report_id=' + constNewReportId)
    setIsLoading(false)
  }

  const handlePNGDownload = () => {
    html2canvas(chartRef.current, { backgroundColor: COLOR.darkBlue }).then(
      canvas => {
        const dataURL = canvas.toDataURL('image/png', 1.0)
        const link = document.createElement('a')
        if (typeof link.download === 'string') {
          link.href = dataURL
          link.download = 'chart.png'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          window.open(dataURL)
        }
      },
      reason => {}
    )
  }

  const handleCsvDownload = () =>
    // @ts-ignore
    setDataSource(tableRef?.current?.getExportData().data)

  const handleExcelDownload = () => {
    //  @ts-ignore
    const options = tableRef.current.workbookOptions()
    const rows = options.sheets[0].rows
    options.sheets[0].frozenRows = 2
    const footerRow = {
      height: 20,
      cells: [
        { value: '© 2021 Nielsen & The U Group ', fontSize: 15, colSpan: 5 }
      ]
    }
    rows.push(footerRow)
    //  @ts-ignore
    tableRef.current.save(options)
  }

  const handlePDFDownload = () => {
    //  @ts-ignore
    const chartVisual = exportVisual(reportMetadata.ref)

    if (chartVisual) {
      //  @ts-ignore
      const visual = exportVisual(reportMetadata.ref, {
        width: PAGE_RECT.size.width
      })
      exportPDF(visual, {
        paperSize: 'A4',
        margin: '1cm',
        landscape: true
      }).then(dataURI => {
        saveAs(dataURI, 'chart.pdf')
      })
    }
  }

  const handleDispatchDialog = (component: React.ReactElement) =>
    dispatch(showDialog(component))

  const handleHideDispatchDialog = () => dispatch(hideDialog())

  const renderDeleteModal = () => {
    const originalUrl = reportMetadata.originalUrl
    const handleOK = async () => {
      await deleteReport(queryReportId.replace('REPORT#', ''))
      history.push(originalUrl)
      handleHideDispatchDialog()
      // TODO - temporary fix to reload page
      window.location.reload()
    }
    handleDispatchDialog(
      <DeleteReportDialog
        onCancel={handleHideDispatchDialog}
        onOk={handleOK}
        reportName={name}
      />
    )
  }

  const handleSetIsLoading = (newLoadingState: boolean) =>
    setIsLoading(newLoadingState)

  const { name } = reportMetadata
  const Component = props.component

  return (
    <ReportContext.Provider
      value={{
        isLoading,
        setIsLoading: handleSetIsLoading
      }}
    >
      <StandardPage pageTitle={name || title}>
        <ReportMetadata
          dataSource={dataSource}
          handleRenameClick={handleRenameClick}
          title={name || title}
          reportMetadata={reportMetadata}
          isCustom={!!name || !!queryReportId}
          handleSaveClick={handleSaveClick}
          resetState={resetState}
          handleSaveAsNew={handleSaveAsNew}
          canSave={reportMetadata.canSave}
          handleCsvClick={handleCsvDownload}
          handleExcelClick={handleExcelDownload}
          handlePNGClick={handlePNGDownload}
          handleDeleteClick={renderDeleteModal}
          handlePDFClick={handlePDFDownload}
        />
        <Component ref={{ chartRef, tableRef }} />
      </StandardPage>
    </ReportContext.Provider>
  )
}

interface IReportPage {
  title: string
  component: React.ReactNode | any
}

export default ReportPage
