import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { MultiCascader } from 'forkedComponents/rsuite'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COLOR } from 'services/styles'

export default () => {
  const products = useSelector(ChartSelector.productsList)
  const dispatch = useDispatch()

  const handleProductChange = (i: string[]) => {
    const currentProducts = products.selected
    if (i.length > currentProducts.length) {
      const difference = i.filter(x => !currentProducts.includes(x))
      if (difference.length === 1) {
        const singleDifference = difference[0]
        if (
          !singleDifference.includes('TOTAL') &&
          !singleDifference.includes('SSB')
        ) {
          const removedArray = i.map(x =>
            x === singleDifference ? `TOTAL ${x}` : x
          )
          return dispatch(ChartActions.setSelectedProducts(removedArray))
        }
      }
    }
    return dispatch(ChartActions.setSelectedProducts(i))
  }

  const isProductLoading = products?.data?.length === 0
  return (
    <MultiCascader
      menuWidth={250}
      labelKey={'label'}
      valueKey={'value'}
      menuHeight={400}
      data={products.data}
      value={products.selected}
      placeholder={isProductLoading && 'Loading..'}
      disabled={isProductLoading}
      onChange={handleProductChange}
      style={{
        width: '100%',
        background: products.selected.length === 0 && COLOR.danger
      }}
    />
  )
}
