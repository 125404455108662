import firebase from 'firebase/app'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { initSentry } from 'services/sentry'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

export const browserHistory = createBrowserHistory()

//  @ts-ignore
initSentry(browserHistory)

browserHistory.listen(location => {
  const analytics = firebase.analytics
  const pagePath = location.pathname + location.search
  analytics().setCurrentScreen(pagePath)
  analytics().logEvent('page_view', { page_path: pagePath })
})

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
