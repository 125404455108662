import CustomGroups from 'components/charts/CustomGroups'
import { ReportContext } from 'components/page/ReportPage/context'
import React from 'react'
import { Col, Grid } from 'rsuite'
import { SPACE } from 'services/styles'
import {
  BuyerGroupDropdown,
  ChainDropdown,
  PeriodDropdown,
  ProductDropdown
} from './dropdowns'
import { Props } from './index'
import { Button, ButtonWithLeftMargin, Row, RowWrapper } from './style'

export default class DataSelector extends React.Component<Props> {
  static contextType = ReportContext

  handleOnQuery = () => {
    const { chainsList, productsList } = this.props
    if (
      chainsList.selected.length === 0 &&
      productsList.selected.length === 0
    ) {
      return alert('Please select some parameters')
    }
    this.props.onComplete()
  }

  renderLabel = (text: string) => (
    <p style={{ marginBottom: SPACE.tiny, height: 20 }}>{text}</p>
  )

  renderCustomGroups = () => {
    const { isCustomGroupOpen } = this.props
    if (isCustomGroupOpen) {
      return (
        <Col xs={24}>
          <CustomGroups active={isCustomGroupOpen} />
        </Col>
      )
    } else {
      return (
        <div>
          <Col xs={8}>
            <>
              {this.renderLabel('Select Product(s)')}
              <ProductDropdown />
            </>
          </Col>
          <Col xs={8}>
            <>
              {this.renderLabel('Select Banner(s)')}
              <ChainDropdown />
            </>
          </Col>
          <Col xs={8}>
            <>
              {this.renderLabel('Select Buyer Group')}
              <BuyerGroupDropdown />
            </>
          </Col>
        </div>
      )
    }
  }

  render() {
    const { resetChartParameters, toggleIsCustomGroupOpen } = this.props
    return (
      <RowWrapper style={{ padding: SPACE.small }}>
        <Grid fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row style={{ alignItems: 'flex-start' }}>
            <Grid fluid style={{ flex: 1 }}>
              {this.renderCustomGroups()}
            </Grid>
            <div>
              {this.renderLabel('Select Period')}
              <PeriodDropdown />
            </div>

            <Button onClick={this.handleOnQuery} style={{ marginTop: 28 }}>
              Apply
            </Button>

            <ButtonWithLeftMargin onClick={resetChartParameters}>
              Reset
            </ButtonWithLeftMargin>

            <ButtonWithLeftMargin onClick={toggleIsCustomGroupOpen}>
              Advanced
            </ButtonWithLeftMargin>
          </Row>
        </Grid>
      </RowWrapper>
    )
  }
}
