import color from 'color'

/** Color scheme */
const BASE_COLOR = {
  success: '#84BF48',
  danger: '#E73D57',
  warning: '#FAAA1D',
  dark: '#2D2D2D',
  emphasis: '#27475D',
  primaryShade1: '#37747B',
  black: '#000',
  light: '#DDDDDD',
  grey: '#4A4A4A',
  lightGrey: '#a8a8a8',
  white: '#FFFFFF',
  dirtyWhite: '#f8f8f8',
  baseDarkBlue: '#0f131a',
  seriesA: '#0275d8',
  seriesB: '#5bc0de',
  seriesC: '#5cb85c',
  seriesD: '#f0ad4e',
  seriesE: '#e67d4a',
  seriesF: '#d9534f'
}

const inferredColor = {
  darkBlue: color(BASE_COLOR.baseDarkBlue).lighten(0.2).hex(),
  mediumDarkBlue: color(BASE_COLOR.baseDarkBlue).lighten(0.8).hex(),
  mediumLightDarkBlue: color(BASE_COLOR.baseDarkBlue).lighten(1.2).hex(),
  lightDarkBlue: color(BASE_COLOR.baseDarkBlue).lighten(1.2).hex(),
  veryLightDarkBlue: color(BASE_COLOR.baseDarkBlue).lighten(2).hex()
}

export const COLOR = {
  ...BASE_COLOR,
  ...inferredColor
}
