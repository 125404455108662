import { Loader } from '@progress/kendo-react-indicators'
import config, { ENVIRONMENT } from 'config'
import { persistor, store } from 'data'
// for kendo react charts
import 'hammerjs'
import { browserHistory } from 'index'
import MainNavigation from 'navigation'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import 'rsuite/dist/styles/rsuite-dark.css'
import { isReadyApi } from 'services/api/requests/common/isReady'
import './App.scss'
import * as Sentry from '@sentry/react'

const App: React.FC = () => {
  // trigger to restart snowflake systems when user comes back to the app
  useEffect(() => {
    const handleActivityTrue = async _ => {
      if (document.visibilityState === 'visible') {
        if (
          config.environment !== ENVIRONMENT.Staging &&
          config.environment !== ENVIRONMENT.Development
        ) {
          await isReadyApi()
        }
      }
    }
    window.addEventListener('visibilitychange', handleActivityTrue)
    return () => {
      window.addEventListener('visibilitychange', handleActivityTrue)
    }
  })

  return (
    <Router history={browserHistory}>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <MainNavigation />
        </PersistGate>
      </Provider>
    </Router>
  )
}

export default Sentry.withErrorBoundary(App, {
  fallback: 'An error has occurred'
})
