import { Loader } from '@progress/kendo-react-indicators'
import StyledLink from 'components/elements/StyledLink'
import StandardPage from 'components/page/StandardPage'
import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Dropdown,
  Grid,
  Icon,
  Input,
  Modal,
  Panel,
  Row
} from 'rsuite'
import {
  deleteReport,
  getReportsViaId
} from 'services/api/requests/reports/common'
import { COLOR, SPACE } from 'services/styles'
import { TitleRow } from './style'

const CARD_HEIGHT = 400
const MainPage: FC = props => {
  const [savedReports, setSavedReports] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    ;(async () => {
      return handleLoadReports()
    })()
  }, [])

  const handleLoadReports = async () => {
    setIsLoading(true)
    const response = await getReportsViaId()
    setSavedReports(response)
    setIsLoading(false)
  }

  const handleDeleteReport = async (reportId: string) => {
    await deleteReport(reportId.replace('REPORT#', ''))
    return handleLoadReports()
  }
  const filteredReports = searchText.length
    ? savedReports.filter(
        item =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.description?.toLowerCase().includes(searchText.toLowerCase())
      )
    : savedReports

  return (
    <StandardPage headerText="Your Saved Reports" pageTitle="Dashboard">
      <Grid fluid style={{ width: '100%' }}>
        <Row>
          <Col xs={24}>
            <Input
              placeholder={'Search for reports'}
              style={{
                backgroundColor: COLOR.dirtyWhite,
                color: COLOR.baseDarkBlue,
                padding: SPACE.tiny * 1.5,
                fontSize: 20,
                marginBottom: 20,
                borderColor: 'grey'
              }}
              value={searchText}
              onChange={setSearchText}
            />
          </Col>
        </Row>
        <Row>
          {isLoading ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  minHeight: '50vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Loader type="infinite-spinner" size="large" />
              </div>
            </div>
          ) : (
            filteredReports.map(item => (
              <Card
                item={item}
                key={item.sk}
                handleClick={() => handleDeleteReport(item.sk)}
              />
            ))
          )}
        </Row>
      </Grid>
    </StandardPage>
  )
}

interface ISavedReports {
  created_at: string
  description: string
  name: string
  pk: string
  route: string
  sk: string
  updated_at: string
  image: string
}

export const Card = ({
  item,
  handleClick
}: {
  item: ISavedReports
  handleClick: () => void
}) => {
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const to = `${item.route}?report_id=${item.sk.replace('REPORT#', '')}`
  const renderDropdown = () => (
    <Dropdown
      noCaret
      trigger="hover"
      icon={<Icon icon="more" style={{ marginRight: 0 }} />}
      placement="bottomEnd"
      style={{ zIndex: 99999 }}
    >
      <Dropdown.Item
        icon={<Icon icon="trash" />}
        onClick={() => setDeleteOpen(true)}
        style={{ zIndex: 99999 }}
      >
        Delete
      </Dropdown.Item>
    </Dropdown>
  )
  return (
    <Col xs={8}>
      <Panel
        bordered
        style={{
          backgroundColor: COLOR.darkBlue,
          marginBottom: 16,
          padding: -SPACE.tiny,
          height: CARD_HEIGHT
        }}
      >
        <div
          style={{
            display: 'flex',
            height: CARD_HEIGHT - 40,
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <TitleRow style={{ fontWeight: 600 }}>
            <StyledLink to={to}>{item.name || '   '} </StyledLink>
            {renderDropdown()}
          </TitleRow>
          <StyledLink to={to}>
            <img
              src={item.image}
              alt={item.name}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </StyledLink>
          <p>{item.description}</p>
          <StyledLink to={to}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button appearance="ghost" style={{ marginTop: 16 }}>
                Go To Report{' '}
                <Icon icon="angle-double-right" style={{ marginLeft: 5 }} />
              </Button>
            </div>
          </StyledLink>
        </div>
      </Panel>
      <Modal backdrop="static" show={isDeleteOpen} size="xs">
        <Modal.Body>
          {`Are you sure you want to delete "${item.name}"?`}
          &nbsp; This operation cannot be undone
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteOpen(false)
              handleClick()
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setDeleteOpen(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}

export default MainPage
