import * as d3 from 'd3-scale-chromatic'

const calculatePoint = (i, intervalSize, colorRangeInfo) => {
  const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize
}

export const interpolateColors = (
  dataLength,
  colorScale = d3.interpolateCool,
  colorRangeInfo = {
    colorStart: 0.2,
    colorEnd: 1,
    useEndAsStart: false
  }
) => {
  const { colorStart, colorEnd } = colorRangeInfo
  const colorRange = colorEnd - colorStart
  const intervalSize = colorRange / dataLength
  let i, colorPoint
  const colorArray = []

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo)
    colorArray.push(colorScale(colorPoint))
  }

  return colorArray
}
