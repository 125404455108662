import { UserActions } from 'data/actions'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock
} from 'rsuite'
import { firebaseLogin } from 'services/firebase'
import { COLOR, SPACE } from 'services/styles'

import logo from 'static/logo-with-name.png'
import * as yup from 'yup'
import { InnerWrapper, Wrapper } from './style'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.+_<>:^])[A-Za-z\d@$!%.*#?&_+<>^:]{8,}$/,
      'Must contain alphanumeric & a special character'
    )
})

export default () => {
  const dispatch = useDispatch()
  const [error, setError] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const handleLogin = async ({ email, password }) => {
    setError('')
    setLoading(true)
    try {
      const response = await firebaseLogin(email, password)
      dispatch(UserActions.setUserAction(response.user.email))
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => handleLogin(values)
  })

  const renderHelper = (text: string) => (
    <HelpBlock style={{ color: COLOR.danger, width: 300 }}>{text}</HelpBlock>
  )

  const emailError = formik.errors.email
  const passwordError = formik.errors.password

  return (
    <Wrapper>
      <InnerWrapper>
        <img
          src={logo}
          style={{ height: 60, margin: SPACE.large }}
          alt="logo"
        />
        <Form onSubmit={(i, e) => formik.handleSubmit(e)}>
          <FormGroup>
            <ControlLabel>Username</ControlLabel>
            <FormControl
              name="name"
              onChange={formik.handleChange('email')}
              value={formik.values.email}
            />
            {emailError && renderHelper(emailError)}
          </FormGroup>
          <FormGroup>
            <ControlLabel>Password</ControlLabel>
            <FormControl
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange('password')}
            />
            {passwordError && renderHelper(passwordError)}
          </FormGroup>
          <FormGroup>
            <ButtonToolbar style={{ textAlign: 'center' }}>
              <Button appearance="primary" type="submit" loading={isLoading}>
                Submit
              </Button>
            </ButtonToolbar>
          </FormGroup>
          {error && renderHelper(error)}
        </Form>
      </InnerWrapper>
    </Wrapper>
  )
}
