import { PeriodEnum } from 'components/charts/ReportParameterSelector'
import { ChartActionTypes } from 'data/chart/types'
import { sagaErrorFallback } from 'data/sagaFallback'
import { ChartSelector } from 'data/selectors'
import { UserActionTypes } from 'data/types'
import firebase from 'firebase'
import { startCase } from 'lodash'
import {
  all,
  call,
  fork,
  ForkEffectDescriptor,
  put,
  select,
  SimpleEffect,
  takeEvery
} from 'redux-saga/effects'
import {
  listBuyerGroupApi,
  listChainsApi,
  listPeriodsApi,
  listProductsApi
} from 'services/api/requests/common/dataSelector'
import { renderTreeRecursive } from 'services/helpers/flatArrayToTree'
import { EnumChainToCanonical } from 'services/helpers/toChain'
import {
  loadBuyerGroupsSuccessful,
  loadChainSuccessful,
  loadPeriodSuccessful,
  loadProductsSuccessful,
  loadRelativePeriodSuccessful,
  setSelectedChains
} from './actions'

function* handleAppInit() {
  const token = yield firebase.auth()?.currentUser?.getIdToken()
  // do not load data if user is not logged in
  if (token) {
    yield all([
      call(loadChains),
      call(loadProducts),
      call(loadBuyerGroups),
      call(loadRelativePeriods),
      call(loadPeriods)
    ])
  }
}

function* loadChains() {
  const data = yield call(listChainsApi)
  const payload = data
    .filter(i => !i.toLowerCase().includes('online'))
    .map(i => ({ text: EnumChainToCanonical[i], id: i }))
  yield put(loadChainSuccessful(payload))
  yield put(setSelectedChains(payload))
}

function* loadRelativePeriods() {
  const payload = Object.values(PeriodEnum).map(i => ({
    id: i,
    text: startCase(i.toLowerCase())
  }))
  yield put(loadRelativePeriodSuccessful(payload))
}

function* loadPeriods() {
  const data = yield call(listPeriodsApi)
  yield put(loadPeriodSuccessful(data))
}

function* loadProducts() {
  const response = yield call(listProductsApi)
  const payload = response.map(renderTreeRecursive)
  yield put(loadProductsSuccessful(payload))
}

function* loadBuyerGroups() {
  const data = yield call(listBuyerGroupApi)
  const response = data.map(renderTreeRecursive)
  yield put(loadBuyerGroupsSuccessful(response))
}

function* handleOnTogglePress() {
  // sagas happen after reducers, may require second put to ensure race
  // condition does not happen
  const isOpen = yield select(ChartSelector.isCustomGroupOpen)
  if (isOpen) {
    const customGroups = yield select(ChartSelector.customGroups)
    if (customGroups.length === 0) {
      const chartParams = yield select(ChartSelector.chartParameterSelector)
      if (
        !!chartParams.buyerGroups.selected ||
        !!chartParams.products.selected
      ) {
        const payload = {
          name: 'Default',
          products: chartParams.products.selected,
          chains: chartParams.chains.selected,
          buyerGroups: chartParams.buyerGroups.selected
        }
        yield put({ type: ChartActionTypes.ADD_CUSTOM_GROUP, payload })
      }
    }
  }
}

/**
 * Only start fetching when the user auth data is present
 * @returns {Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void,
 *   unknown>}
 */
function* watchAppInit() {
  yield takeEvery(
    UserActionTypes.SET_USER_IS_READY,
    sagaErrorFallback,
    handleAppInit
  )
}

function* watchToggleAdvanced() {
  yield takeEvery(
    ChartActionTypes.TOGGLE_CUSTOM_GROUP_OPEN,
    sagaErrorFallback,
    handleOnTogglePress
  )
}

export default function* root() {
  yield all([fork(watchAppInit), fork(watchToggleAdvanced)])
}
