// import { Checkbox } from '@progress/kendo-react-inputs'
import Checkbox from 'forkedComponents/rsuite/Checkbox'
import { DataProps } from 'components/elements/DropdownCheckbox'
import React from 'react'
import { LiRow, TextWithSpacing, UlNoStyle } from './style'

export default ({ data, value, liStyle, onChange }: Props) => {
  const handleCheck = (newCheckedBox: DataProps) => {
    if (value.some(i => i.id === newCheckedBox.id)) {
      return onChange(value.filter(i => i.id !== newCheckedBox.id))
    }
    return onChange([...value, newCheckedBox])
  }

  return (
    <UlNoStyle>
      {data.map(i => (
        <LiRow style={liStyle || {}}>
          <Checkbox
            onClick={_ => handleCheck(i)}
            checked={!!value.find(v => v.id === i.id)}
          />
          <TextWithSpacing>{i.text}</TextWithSpacing>
        </LiRow>
      ))}
    </UlNoStyle>
  )
}

export interface Props {
  data: DataProps[]
  onChange: (data: DataProps[]) => void
  value: DataProps[]
  liStyle?: object
}
