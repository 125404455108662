import StandardPage from 'components/page/StandardPage'
import React, { FC } from 'react'

const Settings: FC = props => {
  return (
    <StandardPage headerText="Settings" pageTitle="Settings"></StandardPage>
  )
}

export default Settings
