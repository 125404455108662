import StyledLink from 'components/elements/StyledLink'
import StandardPage from 'components/page/StandardPage'
import React, { FC } from 'react'
import { Button, Col, Grid, Panel, Row } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import ReportList, { IReports } from '../reports'

const MainPage: FC = props => {
  return (
    <StandardPage headerText="Analysis" pageTitle="Analysis">
      <Grid fluid>
        <Row gutter={16}>
          {ReportList.map(item => (
            <CustomCard item={item} />
          ))}
        </Row>
      </Grid>
    </StandardPage>
  )
}

export const CustomCard = ({ item }: { item: IReports }) => {
  const to = item.path || '#'
  return (
    <Col xs={8}>
      <Panel
        bordered
        header={item.title}
        style={{ backgroundColor: COLOR.darkBlue, marginBottom: 16 }}
      >
        <StyledLink to={to}>
          <p>{item.description}</p>
          {item.image && (
            <img
              src={item.image}
              style={{ width: '100%', height: 200, marginBottom: SPACE.small }}
              alt={item.title}
            />
          )}
          <Button appearance="ghost">Learn More</Button>
        </StyledLink>
      </Panel>
    </Col>
  )
}

export default MainPage
