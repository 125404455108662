import { Button } from '@progress/kendo-react-buttons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import React, { useState } from 'react'
import { COLOR, SPACE } from 'services/styles'

export default ({
  onCancel,
  onComplete,
  defaultName,
  defaultDescription
}: Props) => {
  const [name, setName] = useState<string>(defaultName)
  const [description, setDescription] = useState<string>(defaultDescription)
  return (
    <Dialog
      title={'Rename Report'}
      onClose={onCancel}
      width={600}
      height={'auto'}
    >
      <p style={{ marginBottom: SPACE.tiny, height: 20 }}>Report Name</p>

      <Input
        onChange={i => setName(i.target.value as string)}
        value={name}
        style={{
          width: '100%',
          backgroundColor: COLOR.darkBlue,
          border: name.length ? '' : 'red 1px solid'
        }}
      />
      {!name.length && (
        <p style={{ color: COLOR.danger }}>Report name is required</p>
      )}
      <div style={{ marginBottom: SPACE.small }} />
      <p style={{ marginBottom: SPACE.tiny, height: 20 }}>Description</p>
      <TextArea
        rows={3}
        autoSize
        onChange={i => setDescription(i.value as string)}
        value={description}
        style={{
          width: '100%',
          backgroundColor: COLOR.darkBlue,
          marginBottom: SPACE.tiny
        }}
      />
      <DialogActionsBar>
        <Button
          onClick={() => {
            if (!name.length) return
            onComplete(name, description)
          }}
        >
          Ok
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActionsBar>
    </Dialog>
  )
}

interface Props {
  onCancel: () => void
  onComplete: (text: string, description: string) => void
  defaultName?: string
  defaultDescription?: string
}
