import { Button as KendoButton } from '@progress/kendo-react-buttons'
import { Row as RSuiteRow } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const RowWrapper = styled.div`
  background-color: ${COLOR.mediumDarkBlue};
  border-radius: 5px;
`

export const Button = styled(KendoButton)`
  margin-top: 28px;
`
export const ButtonWithLeftMargin = styled(Button)`
  margin-left: ${SPACE.tiny};
`

export const Row = styled(RSuiteRow)`
  flex-direction: row;
  display: flex;
  align-items: center;
`
