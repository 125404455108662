import PageHeader from 'components/page/PageHeader'
import {
  renderCallbackIcon,
  renderPageIcon
} from 'components/page/Sidebar/iconsRenderer'
import MenuItems from 'components/page/Sidebar/MenuItems'
import {
  BottomNavigation,
  MainWrapper,
  TopNavigation
} from 'components/page/Sidebar/style'
import { setUserAction } from 'data/user/actions'
import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { firebaseLogout } from 'services/firebase'

export default () => {
  const location = useLocation()
  const pathname = location.pathname
  const dispatch = useDispatch()
  const handleLogout = async () => {
    dispatch(setUserAction(undefined))
    return firebaseLogout()
  }
  return (
    <>
      <PageHeader />
      <MainWrapper>
        <TopNavigation>
          {MenuItems.map(i => renderPageIcon(i, pathname))}
        </TopNavigation>
        <BottomNavigation>
          {/* {renderStandardIcon('Help', '', FaQuestionCircle)} */}
          {/* {renderStandardIcon('Notifications', '', FaBell)} */}
          {renderCallbackIcon('Sign out', handleLogout, FaSignOutAlt)}
        </BottomNavigation>
      </MainWrapper>
    </>
  )
}
