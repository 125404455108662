import arrayToTree from 'array-to-tree'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import config from 'config'
import firebase from 'firebase'
import pathToApi from 'services/helpers/pathToApi'

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: { methods: [] }
})

export const listChainsApi = async () => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.post(
    url('/query/list/stores'),
    {},
    { headers: { Authorization: `Bearer ${token}` }, adapter: cache.adapter }
  )
  return data
}

export const listProductsApi = async (
  props?: IListBrands | undefined
): Promise<ProductResponse[]> => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.post(url('/query/list/products'), props, {
    headers: { Authorization: `Bearer ${token}` },
    adapter: cache.adapter
  })
  const treeOutput: TreeInterface[] = []
  treeOutput.push({
    id: 'TOTAL ALCOHOL',
    name: 'TOTAL ALCOHOL',
    parent_id: undefined
  })
  for (const { CATEGORY, MANUFACTURER, BRAND, SUB_BRAND } of data) {
    const renamedCategory = `CAT|${CATEGORY}`
    const renamedManufacturer = `[${renamedCategory}] > PB|${MANUFACTURER}`
    const renamedBrand = `[${renamedManufacturer}] > SB|${BRAND}`
    const renamedSubBrand = `[${renamedBrand}] > SSB|${SUB_BRAND}`

    if (!treeOutput.find(i => i.id === renamedCategory)) {
      treeOutput.push({
        id: renamedCategory,
        name: CATEGORY,
        parent_id: undefined
      })
      treeOutput.push({
        id: `TOTAL ${renamedCategory}`,
        name: `TOTAL ${CATEGORY}`,
        parent_id: renamedCategory
      })
    }

    if (!treeOutput.find(i => i.id === renamedManufacturer)) {
      treeOutput.push({
        id: renamedManufacturer,
        name: MANUFACTURER,
        parent_id: renamedCategory
      })
      treeOutput.push({
        id: `TOTAL ${renamedManufacturer}`,
        name: `TOTAL ${MANUFACTURER}`,
        parent_id: renamedManufacturer
      })
    }

    if (!treeOutput.find(i => i.id === renamedBrand)) {
      treeOutput.push({
        id: renamedBrand,
        name: BRAND,
        parent_id: renamedManufacturer
      })
      if (
        CATEGORY.includes('BEER') &&
        !treeOutput.find(i => i.id === renamedSubBrand)
      ) {
        treeOutput.push({
          id: `TOTAL ${renamedBrand}`,
          name: `TOTAL ${BRAND}`,
          parent_id: renamedBrand
        })
        treeOutput.push({
          id: renamedSubBrand,
          name: SUB_BRAND,
          parent_id: renamedBrand
        })
      }
    }
  }
  return renameKey(arrayToTree(treeOutput))
}

export const listBuyerGroupApi = async () => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.post(
    url('/query/list/buyer-groups'),
    {},
    { headers: { Authorization: `Bearer ${token}` }, adapter: cache.adapter }
  )
  const { income, household_size, age_group } = data
  const treeOutput = [
    { id: 'INCOME', name: 'Income', parent_id: undefined },
    { id: 'HOUSEHOLD_SIZE', name: 'Household Size', parent_id: undefined },
    { id: 'AGE_GROUP', name: 'Age Group', parent_id: undefined }
  ]

  for (const eachIncome of income) {
    treeOutput.push({
      id: `INCOME / ${eachIncome}`,
      name: incomeMap[eachIncome],
      parent_id: 'INCOME'
    })
  }

  for (const eachHouseholdSize of household_size) {
    treeOutput.push({
      id: `HOUSEHOLD_SIZE / ${eachHouseholdSize}`,
      name: householdSizeMap[eachHouseholdSize],
      parent_id: 'HOUSEHOLD_SIZE'
    })
  }

  for (const eachAgeGroup of age_group) {
    treeOutput.push({
      id: `AGE_GROUP / ${eachAgeGroup}`,
      name: eachAgeGroup,
      parent_id: 'AGE_GROUP'
    })
  }
  return renameKey(arrayToTree(treeOutput))
}

export const listPeriodsApi = async () => {
  const url = pathToApi(config.dashboardBaseUrl)
  const token = await firebase.auth().currentUser.getIdToken()
  const { data } = await axios.post(
    url('/query/list/periods'),
    {},
    { headers: { Authorization: `Bearer ${token}` }, adapter: cache.adapter }
  )
  return data
}

const incomeMap = {
  1: 'Low',
  2: 'Mid',
  3: 'High'
}

const householdSizeMap = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5+'
}

const renameKey = (input: arrayToTree.Tree<any>[]): any => {
  const output = []
  if (!input) return input
  for (const eachInput of input) {
    const newOutput = {
      id: eachInput.id,
      label: eachInput.name || '',
      children: renameKey(eachInput.children)
    }
    output.push(newOutput)
  }
  return output
}

interface TreeInterface {
  id: string
  parent_id?: string
  name: string
}

export interface ProductResponse {
  id: string
  children: ProductResponse[]
  label: string
}

interface IListBrands {
  chains?: string[]
  categories?: string[]
}
