import { Icon } from '@progress/kendo-react-common'
import styled from 'styled-components'

export const TextWidth = styled.div`
  flex: 1;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
`

export const Wrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BUTTON_SIZE = 30
export const ButtonStyledWrapper = styled.div`
  border-radius: 30px;
  height: ${BUTTON_SIZE}px;
  width: ${BUTTON_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }
`
export const ButtonStyled = styled(Icon)`
  font-size: 16px;
`
