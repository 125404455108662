import { BORDER_RADIUS, COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const TagWrapper = styled.div`
  border-radius: ${BORDER_RADIUS.standard}px;
  background-color: ${COLOR.baseDarkBlue};
  margin: ${SPACE.tiny}px;
  padding: ${SPACE.tiny / 2}px ${SPACE.tiny}px;
  color: ${COLOR.white};
  flex-direction: row;
  display: flex;
  align-items: center;
`

export const TagLabel = styled.div`
  margin: -${SPACE.tiny}px;
  margin-right: ${SPACE.tiny}px;
  padding: ${SPACE.tiny / 2}px;
`

export const TagText = styled.p`
  margin: 0px;
`
