import {
  TrendedMetricEnumToKey,
  TrendedMetricNonAdditiveEnum,
  TrendedMetricsResponseNonAdditive
} from 'pages/reports/reports/trendedMetricsNonAdditive/types'

export const manufacturerColorMap = {}

export default (
  rawData: TrendedMetricsResponseNonAdditive[],
  rawSelectedMetric: TrendedMetricNonAdditiveEnum | string
): {
  key: TrendedMetricNonAdditiveEnum | string
  period: string
  value: any
}[] => {
  const correctedName = TrendedMetricEnumToKey[rawSelectedMetric]
  return rawData.map(i => ({
    key: rawSelectedMetric,
    period: i.period,
    value: i[correctedName]
  }))
}

interface TrendedMetricsData {
  manufacturers: string[]
  data: any[]
}

const colors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600'
]

function getRandomColor() {
  return colors[Math.floor(Math.random() * colors.length)]
}
