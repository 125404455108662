import { DataProps } from 'components/elements/DropdownCheckbox'
import { TreeData } from 'services/helpers/flatArrayToTree'

export enum ChartActionTypes {
  LOAD_CHAINS_SUCCESSFUL = 'CHARTS/LOAD_CHAINS_SUCCESSFUL',
  LOAD_PRODUCTS_SUCCESSFUL = 'CHARTS/LOAD_PRODUCTS_SUCCESSFUL',
  LOAD_BUYER_GROUPS_SUCCESSFUL = 'CHARTS/LOAD_BUYER_GROUPS_SUCCESSFUL',
  LOAD_PERIODS_SUCCESSFUL = 'CHARTS/LOAD_PERIODS_SUCCESSFUL',
  LOAD_RELATIVE_PERIODS_SUCCESSFUL = 'CHARTS/LOAD_RELATIVE_PERIODS_SUCCESSFUL',
  SET_SELECTED_PRODUCTS = 'CHARTS/SET_SELECTED_PRODUCTS',
  SET_SELECTED_CHAINS = 'CHARTS/SET_SELECTED_CHAINS',
  SET_SELECTED_BUYER_GROUPS = 'CHARTS/SET_SELECTED_BUYER_GROUPS',
  SET_SELECTED_PERIOD = 'CHARTS/SET_SELECTED_PERIOD',
  ADD_CUSTOM_GROUP = 'CHARTS/ADD_CUSTOM_GROUP',
  EDIT_CUSTOM_GROUP = 'CHARTS/EDIT_CUSTOM_GROUP',
  DELETE_CUSTOM_GROUP = 'CHARTS/DELETE_CUSTOM_GROUP',
  RESET_CHART_PARAMETERS = 'CHARTS/RESET_CHART_PARAMETERS',
  REHYDRATE_CHART_PARAMETERS = 'CHARTS/REHYDRATE_CHART_PARAMETERS',
  TOGGLE_CUSTOM_GROUP_OPEN = 'CHARTS/TOGGLE_CUSTOM_GROUP_OPEN'
}

export interface ChartParameter {
  chains: ChartListParameter
  products: ChartTreeParameter
  buyerGroups: ChartTreeParameter
  periods: ChartListPeriodParameter
  isCustomGroupOpen: boolean
}

interface ChartTreeParameter {
  data: TreeData[]
  selected: string[]
}

interface ChartListParameter {
  data: SingleSelect[]
  selected: SingleSelect[]
}

interface ChartListSingleParameter {
  data: string[]
  selected: SingleSelect
}

interface ChartListPeriodParameter extends ChartListSingleParameter {
  relativePeriods: SingleSelect[]
}

interface SingleSelect {
  id: string
  text: string
}

interface ChartCustomGroup {
  name: string
  products: string[]
  chains: DataProps[]
  buyerGroups: string[]
  periods: string[]
}

export interface ChartCustomGroups {
  groups: ChartCustomGroup[]
}

export interface ChartMeta {
  chartRef: null
  dataTableRef: null
}
