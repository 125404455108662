import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid'
import ChartAdditionalSelectorsWrapper from 'components/charts/ChartAdditionalSelectorsWrapper'
import ChartWrapper from 'components/charts/ChartWrapper'
import DataGridSortable from 'components/charts/DataGridSortable'
import ReportParameterSelector from 'components/charts/ReportParameterSelector'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import ReportPage from 'components/page/ReportPage'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { startCase } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdditiveTrendedMetricsApi } from 'services/api/requests/reports/trendedMetricsAdditive'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { isLargeScreen } from 'services/helpers/checkWindowScreen'
import objectToCamelCase from 'services/helpers/objectToCamelCase'
import { periodListToText, periodToText } from 'services/helpers/toPeriod'
import { SPACE } from 'services/styles'
import formatData from './formatData'
import {
  TrendedMetricAdditiveEnum,
  TrendedMetricsResponseAdditive
} from './types'

const initialDropdownState = {
  text: startCase(TrendedMetricAdditiveEnum.PURCHASE_VALUE.toLowerCase()),
  id: TrendedMetricAdditiveEnum.PURCHASE_VALUE
}

const trendedMetricEnumList = Object.values(TrendedMetricAdditiveEnum).map(
  i => ({
    id: i,
    text: startCase(i.toLowerCase())
  })
)

export const SubComponent = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  const [rawData, setRawData] = useState<TrendedMetricsResponseAdditive[]>([])
  const [metric, setMetric] = useState(initialDropdownState)
  const [legendPosition, setLegendPosition] = useState<
    'left' | 'bottom' | 'right' | 'top' | 'custom'
  >(isLargeScreen(window.innerWidth) ? 'right' : 'bottom')
  const { isLoading, setIsLoading } = useContext(ReportContext)
  const params = useSelector(ChartSelector.chartQuerySelector)

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const handleWindowResize = () =>
    isLargeScreen(window.innerWidth)
      ? setLegendPosition('right')
      : setLegendPosition('bottom')

  const handleLoad = async () => {
    setIsLoading(true)
    const response = await getAdditiveTrendedMetricsApi(params)
    const processedResponse = Object.entries<any[]>(response).reduce(
      (combinedAcc: object[], [key, value]) => {
        const perKey = value.reduce(
          (acc, data) => [...acc, { ...data, key }],
          []
        )
        return [...combinedAcc, ...perKey]
      },
      []
    )
    setRawData(processedResponse.map(objectToCamelCase))
    setIsLoading(false)
  }

  const data = formatData(rawData, metric.id)
  const chartTitle = startCase(metric.id.toLowerCase())

  return (
    <>
      <ReportParameterSelector onComplete={handleLoad} />
      <ChartWrapper isLoading={isLoading}>
        <ChartAdditionalSelectorsWrapper style={{ justifyContent: 'flex-end' }}>
          <DropDownList
            data={trendedMetricEnumList}
            textField="text"
            dataItemKey="id"
            value={metric}
            onChange={i => setMetric(i.value)}
          />
        </ChartAdditionalSelectorsWrapper>
        <div ref={chartRef}>
          <Chart transitions={false}>
            <ChartTitle text={chartTitle} margin={30} />
            <ChartArea background={'transparent'} />
            <ChartLegend position={legendPosition} />
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{ text: 'Index' }}
                labels={{ visible: true, format: '#' }}
              />
            </ChartValueAxis>
            <ChartTooltip background={'rgba(0,0,0,0.5)'} shared={true} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem categories={periodListToText(data.labels)}>
                <ChartCategoryAxisTitle text="Period" margin={SPACE.small} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
              {data.datasets.map(i => (
                <ChartSeriesItem
                  type="line"
                  data={i.data}
                  name={changeTextIfLowNumber(i.label, i.isLowNumber)}
                />
              ))}
            </ChartSeries>
          </Chart>
        </div>
        <MemoizedRenderTable data={rawData} dataTableRef={tableRef} />
        <SampleSizeWarningText />
      </ChartWrapper>
    </>
  )
})

const RenderDataTable = ({ data, dataTableRef }) => {
  const dataGridData = data.map(i => ({
    ...i,
    canonicalPeriod: periodToText(i.period),
    key: changeTextIfLowNumber(i.key, i.isLowNumber)
  }))
  return (
    <ExcelExport data={dataGridData} ref={dataTableRef}>
      <DataGridSortable data={dataGridData}>
        <DataColumn field="canonicalPeriod" title="Period" />
        <DataColumn field="key" title="Groups" />
        {/* <Column field="rawCount" title="Raw purchases" format="{0:n0}"/> */}
        <DataColumn
          field="shareByValue"
          title="Share of Category (By Value)"
          format="{0:n2}"
        />
        <DataColumn
          field="shareByOccasions"
          title="Share of Category (By Occasions)"
          format="{0:n2}"
        />
        <DataColumn
          field="purchaseValue"
          title="Purchase Value"
          format="{0:n0}"
        />
        <DataColumn
          field="purchaseUnits"
          title="Purchase Units"
          format="{0:n0}"
        />
      </DataGridSortable>
    </ExcelExport>
  )
}
const MemoizedRenderTable = React.memo(RenderDataTable)

export default () => (
  <ReportPage title="Trended Metrics (Additive)" component={SubComponent} />
)
