export enum UserActionTypes {
  SET_USER = 'USER/SET_USER',
  SET_USER_IS_READY = 'USER/SET_USER_IS_READY',
  SET_FIREBASE_READY = 'USER/SET_FIREBASE_READY'
}

export interface UserState {
  email?: string
  isFirebaseReady: boolean
}
