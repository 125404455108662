import OrganizationChart from '@dabeng/react-orgchart'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import ChartAdditionalSelectorsWrapper from 'components/charts/ChartAdditionalSelectorsWrapper'
import ChartWrapper from 'components/charts/ChartWrapper'
import DataGridSortable from 'components/charts/DataGridSortable'
import ReportParameterSelector from 'components/charts/ReportParameterSelector'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import ReportPage from 'components/page/ReportPage'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import {
  paramsToPeriodsMatrix,
  separator
} from 'pages/reports/reports/common/helpers'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Grid } from 'rsuite'
import { getPurchaseDriversApi } from 'services/api/requests/reports/purchaseDrivers'
import objectToCamelCase from 'services/helpers/objectToCamelCase'
import { COLOR, SPACE } from 'services/styles'
import formatData from './formatData'
import Node from './node'
import './override.scss'
import { MetricRow } from './style'
import { IOrgChart } from './types'

export const SubComponent = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [combinedRawData, setCombinedRawData] = useState({})
  const [leftMetric, setLeftMetric] = useState({ text: null, id: null })
  const [rightMetric, setRightMetric] = useState({ text: null, id: null })
  const [byGroup, setByGroup] = useState({ text: null, id: null })
  const [groupCombinations, setGroupCombinations] = useState<{
    timePeriods: any[]
    groupNames: any[]
  }>({ timePeriods: [], groupNames: [] })
  const params = useSelector(ChartSelector.chartQuerySelector)
  const isCustomGroupOpen = useSelector(ChartSelector.isCustomGroupOpen)

  useEffect(() => {
    if (isCustomGroupOpen && !byGroup.id) {
      setByGroup(groupCombinations.groupNames[0])
    }
  }, [combinedRawData])
  const handleLoad = async () => {
    setIsLoading(true)
    const queries = paramsToPeriodsMatrix(params)
    const response = await getPurchaseDriversApi(queries)
    const camelCaseResponse = Object.entries(response).reduce(
      (acc, [key, value]) => {
        acc[key] = objectToCamelCase(value)
        return acc
      },
      {}
    )
    const groupNames = Array.from(
      new Set(Object.keys(camelCaseResponse).map(i => i.split(separator)[0]))
    ).map(i => ({ id: i, text: i }))

    const timePeriods = Array.from(
      new Set(Object.keys(camelCaseResponse).map(i => i.split(separator)[1]))
    ).map(i => ({ id: i, text: i }))

    setGroupCombinations({ groupNames, timePeriods })
    setCombinedRawData(camelCaseResponse)
    setIsLoading(false)
  }

  const renderSelect = (
    selected: { text: null; id: null },
    handleChange: (any: any) => void,
    errorLabel: string,
    dropdownTitle: string,
    data
  ) => {
    return (
      <div>
        <p style={{ textAlign: 'center', marginBottom: SPACE.tiny }}>
          {dropdownTitle}
        </p>
        <DropDownList
          data={data}
          textField="text"
          dataItemKey="id"
          value={selected}
          onChange={handleChange}
          style={{ marginRight: SPACE.tiny }}
        />
        {!selected.id && (
          <p
            style={{
              marginTop: SPACE.tiny,
              color: COLOR.danger,
              textAlign: 'center'
            }}
          >
            {errorLabel}
          </p>
        )}
      </div>
    )
  }

  let leftRawDataKey = `Default${separator}${leftMetric?.id}`
  let rightRawDataKey = `Default${separator}${rightMetric?.id}`

  if (isCustomGroupOpen) {
    leftRawDataKey = `${byGroup?.id}${separator}${leftMetric?.id}`
    rightRawDataKey = `${byGroup?.id}${separator}${rightMetric?.id}`
  }

  const data = formatData({
    target: combinedRawData[leftRawDataKey] || undefined,
    benchmark: combinedRawData[rightRawDataKey] || undefined
  })

  return (
    <>
      <ReportParameterSelector onComplete={handleLoad} />
      <ChartWrapper isLoading={isLoading}>
        <ChartAdditionalSelectorsWrapper>
          <MetricRow>
            {isCustomGroupOpen &&
              renderSelect(
                byGroup,
                i => setByGroup(i.value),
                'Please select a group',
                'Group',
                groupCombinations.groupNames
              )}
          </MetricRow>
          <MetricRow>
            {renderSelect(
              leftMetric,
              i => setLeftMetric(i.value),
              'Please select a period',
              'Period',
              groupCombinations.timePeriods
            )}
            <h3
              style={{
                marginBottom: 0,
                marginLeft: SPACE.large,
                marginRight: SPACE.large
              }}
            >
              vs
            </h3>
            {renderSelect(
              rightMetric,
              i => setRightMetric(i.value),
              'Please select a period',
              'Benchmark Period',
              groupCombinations.timePeriods
            )}
          </MetricRow>
        </ChartAdditionalSelectorsWrapper>
        <Grid fluid style={{ flex: 1 }}>
          <Col
            lg={16}
            md={24}
            sm={24}
            style={{ color: 'black', marginTop: 20 }}
          >
            {!leftMetric.id || !rightMetric.id ? (
              <p style={{ color: COLOR.white }}>
                Please select a target period and a benchmark period
              </p>
            ) : (
              <div ref={chartRef}>
                <OrganizationChart
                  collapsible={false}
                  datasource={data}
                  chartClass="myChart"
                  NodeTemplate={Node}
                />
              </div>
            )}
          </Col>
          <Col lg={8} md={24} sm={24}>
            <MemoizedRenderTable data={data} dataTableRef={tableRef} />
          </Col>
        </Grid>
        <SampleSizeWarningText />
      </ChartWrapper>
    </>
  )
})

const RenderDataTable = ({ data, dataTableRef }) => {
  const flattenTree = (tree: IOrgChart) => {
    if (tree?.children?.length) {
      return [
        tree,
        ...tree.children.reduce(
          (acc, item) => [...acc, ...flattenTree(item)],
          []
        )
      ]
    }
    return [tree]
  }
  const flattenedData = flattenTree(data)
  return (
    <ExcelExport data={flattenedData} ref={dataTableRef}>
      <DataGridSortable data={flattenedData}>
        <DataColumn field="name" title="Description" />
        <DataColumn field="value" title="Value" />
      </DataGridSortable>
    </ExcelExport>
  )
}

const MemoizedRenderTable = React.memo(RenderDataTable)

export default () => (
  <ReportPage title="Purchase Drivers" component={SubComponent} />
)
