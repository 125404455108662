import { Button } from '@progress/kendo-react-buttons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout'
import SelectionTag from 'components/charts/SelectionTag'
import { DataProps } from 'components/elements/DropdownCheckbox'
import ListWithCheckbox from 'components/elements/ListWithCheckbox'
import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { MultiCascader } from 'forkedComponents/rsuite'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Grid } from 'rsuite'
import { COLOR } from 'services/styles'
import { ColumnWrapper, GroupName, SectionTitle, TagWrapper } from './style'
import './style.scss'

const modalHeight = 20
const modalWidth = 20

interface ChartCustomGroup {
  name: string
  products: string[]
  chains: DataProps[]
  buyerGroups: string[]
}

const alertMessage = {
  exist: 'That group name exist, please select a new name',
  missing: 'Please fill in a group name'
}

export default ({
  onCancel,
  onComplete,
  customGroup: prefilledGroup = undefined as ChartCustomGroup
}) => {
  const products = useSelector(ChartSelector.productsList)
  const chainsList = useSelector(ChartSelector.chainsList)
  const buyerGroups = useSelector(ChartSelector.buyerGroupsList)
  const customGroups = useSelector(ChartSelector.customGroups)
  const dispatch = useDispatch()
  const [disabledItemValues, setDisabledItemValues] = useState([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    prefilledGroup?.products || []
  )

  const [selectedBanners, setSelectedBanners] = useState<DataProps[]>(
    prefilledGroup?.chains || chainsList.data
  )

  const [selectedBuyerGroups, setSelectedBuyerGroups] = useState<string[]>(
    prefilledGroup?.buyerGroups || []
  )

  const [groupName, setGroupName] = useState<string>(prefilledGroup?.name || '')

  const handleCreateNewGroup = () => {
    dispatch(
      ChartActions.addCustomGroup({
        name: groupName,
        products: selectedProducts,
        chains: selectedBanners,
        buyerGroups: selectedBuyerGroups
      })
    )
  }

  const handleEditCurrentGroup = () => {
    const prevName = prefilledGroup.name
    const updatedGroup = {
      ...prefilledGroup,
      name: groupName,
      products: selectedProducts,
      chains: selectedBanners,
      buyerGroups: selectedBuyerGroups
    }
    dispatch(ChartActions.editCustomGroup(prevName, updatedGroup))
  }

  const handleBuyerGroupChange = (newChange: string[]) => {
    if (newChange.length === 0) {
      setSelectedBuyerGroups([])
      return setDisabledItemValues([])
    }
    const filteredList = newChange.filter(i => i.includes('/'))
    let prefix

    if (filteredList.length === 0) prefix = newChange[0]
    else prefix = filteredList[0].split(' / ')[0]

    const disabledList = []
    for (const eachEntry of ['HOUSEHOLD_SIZE', 'INCOME', 'AGE_GROUP']) {
      if (prefix !== eachEntry) disabledList.push(eachEntry)
    }
    setDisabledItemValues(disabledList)
    setSelectedBuyerGroups(newChange)
  }

  const handleProductChange = (i: string[]) => {
    const currentProducts = selectedProducts
    if (i.length > currentProducts.length) {
      const difference = i.filter(x => !currentProducts.includes(x))
      if (difference.length === 1) {
        const singleDifference = difference[0]
        if (
          !singleDifference.includes('TOTAL') &&
          !singleDifference.includes('SSB')
        ) {
          const removedArray = i.map(x =>
            x === singleDifference ? `TOTAL ${x}` : x
          )
          return setSelectedProducts(removedArray)
        }
      }
    }
    return setSelectedProducts(i)
  }

  const handleBannerChange = (newChange: DataProps[]) =>
    setSelectedBanners(newChange)

  const handleOnComplete = () => {
    if (!groupName.length) return alert(alertMessage.missing)
    if (prefilledGroup) handleEditCurrentGroup()
    else if (customGroups.find(item => item.name === groupName)) {
      return alert(alertMessage.exist)
    } else handleCreateNewGroup()
    onComplete()
  }

  return (
    <Dialog
      onClose={onCancel}
      width={`calc(100vw - ${modalWidth}vw)`}
      height={`calc(100vh - ${modalHeight}vh)`}
    >
      <Grid fluid style={{ flex: 1 }}>
        <Col xs={15}>
          <ColumnWrapper>
            <SectionTitle>Custom Groups</SectionTitle>
            <GroupName
              onChange={i => setGroupName(i.value)}
              id={'custom-group-name-input'}
              placeholder={'Please type in a group name'}
              value={groupName}
              style={{
                backgroundColor: COLOR.light,
                color: COLOR.dark,
                border: groupName?.length > 0 ? '' : 'red 1px solid'
              }}
            />
            {!groupName.length && (
              <p style={{ color: COLOR.danger }}>Group name is required</p>
            )}
            <hr style={{ borderTop: `1px solid ${COLOR.dirtyWhite}` }} />
            <SectionTitle>Select Groups</SectionTitle>
            <PanelBar expandMode={'single'}>
              <PanelBarItem title="Products (Select at least one)">
                <MultiCascader
                  menuWidth={220}
                  menuHeight={300}
                  labelKey={'label'}
                  valueKey={'value'}
                  data={products.data}
                  value={selectedProducts}
                  inline
                  menuStyle={{ overflow: 'scroll' }}
                  style={{ width: '100%' }}
                  onChange={handleProductChange}
                />
              </PanelBarItem>
              <PanelBarItem title="Banners">
                <ListWithCheckbox
                  onChange={handleBannerChange}
                  value={selectedBanners}
                  data={chainsList.data}
                  liStyle={{ backgroundColor: '#2a2d32' }}
                />
              </PanelBarItem>
              <PanelBarItem title="Buyer Group">
                <MultiCascader
                  menuAutoWidth={true}
                  menuWidth={220}
                  menuHeight={250}
                  labelKey={'label'}
                  valueKey={'value'}
                  data={buyerGroups.data}
                  value={selectedBuyerGroups}
                  disabledItemValues={disabledItemValues}
                  uncheckableItemValues={[
                    'HOUSEHOLD_SIZE',
                    'INCOME',
                    'AGE_GROUP'
                  ]}
                  inline
                  onChange={handleBuyerGroupChange}
                />
              </PanelBarItem>
            </PanelBar>
          </ColumnWrapper>
        </Col>
        <Col xs={2} />
        <Col xs={9}>
          <SectionTitle>Selections</SectionTitle>
          <PanelBar>
            <PanelBarItem title="Products" expanded={true}>
              <TagWrapper>
                {selectedProducts.map(i => (
                  <SelectionTag text={i} />
                ))}
              </TagWrapper>
            </PanelBarItem>
            <PanelBarItem title="Banners" expanded={true}>
              <TagWrapper>
                {selectedBanners.map(i => (
                  <SelectionTag text={i.text} />
                ))}
              </TagWrapper>
            </PanelBarItem>
            <PanelBarItem title="Buyer Groups" expanded={true}>
              <TagWrapper>
                {selectedBuyerGroups.map(i => (
                  <SelectionTag text={i} />
                ))}
              </TagWrapper>
            </PanelBarItem>
          </PanelBar>
        </Col>
      </Grid>
      <DialogActionsBar>
        <Button onClick={handleOnComplete}>Ok</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActionsBar>
    </Dialog>
  )
}
