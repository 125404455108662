import { combineReducers } from 'redux'
import chart, { CombinedChartState } from './chart/reducer'
import dialog, { CombinedDialogState } from './dialog/reducer'
import user, { CombinedUserState } from './user/reducer'

export const state = combineReducers<AppReducerState>({
  user,
  dialog,
  chart
})

export interface AppReducerState {
  readonly user: CombinedUserState
  readonly dialog: CombinedDialogState
  readonly chart: CombinedChartState
}

export default state
