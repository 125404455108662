import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { MultiCascader } from 'forkedComponents/rsuite'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const [disabledItemValues, setDisabledItemValues] = useState([])
  const dispatch = useDispatch()
  const buyerGroups = useSelector(ChartSelector.buyerGroupsList)
  const handleBuyerGroupChange = (newChange: string[]) => {
    if (newChange.length === 0) {
      dispatch(ChartActions.setSelectedBuyerGroups([]))
      return setDisabledItemValues([])
    }
    const filteredList = newChange.filter(i => i.includes('/'))
    let prefix

    if (filteredList.length === 0) prefix = newChange[0]
    else prefix = filteredList[0].split(' / ')[0]

    const disabledList = []
    for (const eachEntry of ['HOUSEHOLD_SIZE', 'INCOME', 'AGE_GROUP']) {
      if (prefix !== eachEntry) disabledList.push(eachEntry)
    }
    setDisabledItemValues(disabledList)
    dispatch(ChartActions.setSelectedBuyerGroups(newChange))
  }
  const isBuyerGroupsLoading = buyerGroups?.data?.length === 0
  return (
    <MultiCascader
      menuWidth={250}
      labelKey={'label'}
      valueKey={'value'}
      menuHeight={400}
      data={buyerGroups.data}
      value={buyerGroups.selected}
      disabledItemValues={disabledItemValues}
      placeholder={(isBuyerGroupsLoading && 'Loading..') || 'All Buyer Groups'}
      uncheckableItemValues={['HOUSEHOLD_SIZE', 'INCOME', 'AGE_GROUP']}
      disabled={isBuyerGroupsLoading}
      onChange={handleBuyerGroupChange}
      style={{ width: '100%' }}
    />
  )
}
