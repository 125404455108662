import SelectionTag from 'components/charts/SelectionTag'
import { DeleteCustomGroupDialog } from 'components/dialogs'
import CustomGroup from 'components/dialogs/CustomGroup'
import { cloneDeep } from 'lodash'
import React from 'react'
import { Row } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import { Props } from '.'
import GroupRow from './GroupRow'
import {
  GroupingRow,
  GroupingRowContentRow,
  GroupingRowContentRowClickable
} from './style'

export default (props: Props) => {
  const { groups, showDialog, hideDialog, active, deleteCustomGroup } = props

  if (!active) return null

  const handleAddNewGroup = () =>
    showDialog(<CustomGroup onCancel={hideDialog} onComplete={hideDialog} />)

  const handleEditGroup = (key: string) => {
    const chartCustomGroup = cloneDeep(groups)
    const foundGroup = chartCustomGroup.find(group => group.name === key)
    showDialog(
      <CustomGroup
        onCancel={hideDialog}
        onComplete={hideDialog}
        customGroup={foundGroup}
      />
    )
  }

  const handleDeleteGroup = (key: string) => {
    const handleOK = () => {
      deleteCustomGroup(key)
      hideDialog()
    }
    showDialog(
      <DeleteCustomGroupDialog
        onCancel={hideDialog}
        onOk={handleOK}
        groupName={key}
      />
    )
  }

  const renderChains = (chains: any) => {
    if (chains?.length === 4 || chains?.length === 0) {
      return <SelectionTag key={'All Banners'} text={'All Banners'} />
    }
    return chains?.map(item => (
      <SelectionTag key={item.text} text={item.text} />
    ))
  }

  const renderBuyerGroups = (buyerGroups: any) => {
    if (buyerGroups?.length === 0) {
      return <SelectionTag key={'All Buyers'} text={'All Buyers'} />
    }
    return buyerGroups?.map(item => (
      <SelectionTag key={item.text} text={item.text} />
    ))
  }

  return (
    <Row style={{ marginTop: SPACE.small }}>
      {groups.map(item => {
        return (
          <GroupingRowContentRow
            style={{ backgroundColor: COLOR.mediumLightDarkBlue }}
            key={item.name}
          >
            <GroupRow
              text={item.name}
              hasButtons
              onRenameClick={_ => handleEditGroup(item.name)}
              onDeleteClick={_ => handleDeleteGroup(item.name)}
            />
            <GroupingRow>
              {item.products?.map(item => (
                <SelectionTag key={item} text={item} />
              ))}
              {renderChains(item.chains)}
              {renderBuyerGroups(item.buyerGroups)}
              {item.buyerGroups?.map(item => (
                <SelectionTag key={item} text={item} />
              ))}
            </GroupingRow>
          </GroupingRowContentRow>
        )
      })}
      <GroupingRowContentRow>
        <GroupingRowContentRowClickable onClick={handleAddNewGroup}>
          + Create New Group
        </GroupingRowContentRowClickable>
      </GroupingRowContentRow>
    </Row>
  )
}
