import Demographics from 'pages/reports/reports/demographics'
import PurchaseDrivers from 'pages/reports/reports/purchaseDrivers'
import TrendedMetricsAdditive from 'pages/reports/reports/trendedMetricsAdditive'
import TrendedMetricsNonAdditive from 'pages/reports/reports/trendedMetricsNonAdditive'
import React from 'react'
import routes from 'routes'
import DemographicsImage from 'static/reports/demographics.png'
import PurchaseDriversImage from 'static/reports/purchase-drivers.png'
import TrendedMetricsAdditiveImage from 'static/reports/trended-metrics-additive.png'
import TrendedMetricsNonAdditiveImage from 'static/reports/trended-non-additive.png'

const ReportList: IReports[] = [
  // {
  //   title: 'Table Report',
  //   description: '',
  //   component: TableReport,
  //   path: routes.reports.tableReport(),
  //   image:
  // },
  {
    title: 'Trended Metrics (Additive)',
    description: '',
    component: TrendedMetricsAdditive,
    path: routes.reports.trendedMetricsAdditive(),
    image: TrendedMetricsAdditiveImage
  },
  {
    title: 'Trended Metrics (Non-Additive)',
    description: '',
    component: TrendedMetricsNonAdditive,
    path: routes.reports.trendedMetricsNonAdditive(),
    image: TrendedMetricsNonAdditiveImage
  },
  {
    title: 'Purchase Drivers',
    description: '',
    component: PurchaseDrivers,
    path: routes.reports.purchaseDrivers(),
    image: PurchaseDriversImage
  },
  {
    title: 'Demographics',
    description: '',
    component: Demographics,
    path: routes.reports.demographics(),
    image: DemographicsImage
  }
]

export interface IReports {
  title: string
  description: string
  path: string
  component: React.ComponentType<any>
  image?: string
}

export default ReportList
