import ReportParameterSelector from 'components/charts/ReportParameterSelector/component'
import { ChartActions, DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { connect } from 'react-redux'

export enum PeriodEnum {
  LAST_4_WEEKS = 'LAST_4_WEEKS',
  LAST_12_WEEKS = 'LAST_12_WEEKS',
  LAST_26_WEEKS = 'LAST_26_WEEKS',
  LAST_52_WEEKS = 'LAST_52_WEEKS'
}

export interface ReactProps {
  onComplete: () => void
}

export const mapDispatchToProps = {
  showDialog: DialogActions.showDialog,
  hideDialog: DialogActions.hideDialog,
  setSelectedChains: ChartActions.setSelectedChains,
  resetChartParameters: ChartActions.resetChartParameters,
  toggleIsCustomGroupOpen: ChartActions.toggleIsCustomGroupOpen
}

export const mapStateToProps = (state: any) => ({
  productsList: ChartSelector.productsList(state),
  buyerGroupsList: ChartSelector.buyerGroupsList(state),
  chainsList: ChartSelector.chainsList(state),
  isCustomGroupOpen: ChartSelector.isCustomGroupOpen(state)
})

export type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportParameterSelector)
