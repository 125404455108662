import { Tooltip } from '@progress/kendo-react-tooltip'
import { IMenuItem } from 'components/page/Sidebar/MenuItems'
import {
  defaultMenuButtonProps,
  SidebarButtons
} from 'components/page/Sidebar/style'
import React from 'react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'

export const renderCallbackIcon = (
  title: string,
  cb: () => void,
  Icon: IconType
) => (
  <SidebarButtons onClick={cb} title={title} key={title}>
    <Tooltip anchorElement="target" position="right" openDelay={0} parentTitle>
      <Icon style={{ fontSize: 22 }} />
    </Tooltip>
  </SidebarButtons>
)

export const renderStandardIcon = (
  title: string,
  path: string,
  Icon: IconType
) => (
  <Link to={path}>
    <SidebarButtons title={title}>
      <Tooltip
        anchorElement="target"
        position="right"
        openDelay={0}
        parentTitle
      >
        <Icon style={{ fontSize: 22 }} />
      </Tooltip>
    </SidebarButtons>
  </Link>
)

export const renderPageIcon = (item: IMenuItem, pathname: string) => {
  const isActive =
    item.path === pathname ||
    (item.items ? item.items.some(i => i.path === pathname) : false)
  const Icon = item.icon
  return (
    <Link to={item.path} key={item.path}>
      <SidebarButtons
        title={item.title}
        style={{ color: isActive ? 'rgb(22, 157, 224)' : 'white' }}
      >
        <Tooltip
          anchorElement="target"
          position="right"
          openDelay={0}
          parentTitle
        >
          <Icon {...defaultMenuButtonProps} />
        </Tooltip>
      </SidebarButtons>
    </Link>
  )
}
