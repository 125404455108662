import { ChartActions, DialogActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import { connect } from 'react-redux'
import CustomGroups from './component'

export enum PeriodEnum {
  LAST_4_WEEKS = 'LAST_4_WEEKS',
  LAST_12_WEEKS = 'LAST_12_WEEKS',
  LAST_26_WEEKS = 'LAST_26_WEEKS',
  LAST_52_WEEKS = 'LAST_52_WEEKS'
}

export interface ReactProps {
  active: boolean
}

export const mapDispatchToProps = {
  showDialog: DialogActions.showDialog,
  hideDialog: DialogActions.hideDialog,
  deleteCustomGroup: ChartActions.deleteCustomGroup,
  setSelectedChains: ChartActions.setSelectedChains,
  resetChartParameters: ChartActions.resetChartParameters
}

export const mapStateToProps = (state: any) => ({
  groups: ChartSelector.customGroups(state)
})

export type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactProps

export default connect(mapStateToProps, mapDispatchToProps)(CustomGroups)
