import { Button as RSuiteButton } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const ROOT_MENU_WIDTH = 70
export const SUBMENU_WIDTH = 250
export const CLOSED_SUBMENU_WIDTH = 20
export const PAGE_HEADER_HEIGHT = 75

export const MainWrapper = styled.div`
  position: fixed;
  padding: 10px;
  width: ${ROOT_MENU_WIDTH}px;
  left: 0;
  bottom: 0;
  top: ${PAGE_HEADER_HEIGHT}px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLOR.darkBlue};
`

export const TopNavigation = styled.div``

export const BottomNavigation = styled.div``

export const ButtonRounded = styled(RSuiteButton)``

export const SidebarButtons = styled.div`
  height: 50px;
  width: 50px;
  background: none;
  padding: 0;
  margin-bottom: ${SPACE.tiny}px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  color: white;
  &:hover {
    cursor: pointer;
    background-color: ${COLOR.mediumLightDarkBlue};
  }
`

export const SubMenuWrapper = styled.div`
  left: ${ROOT_MENU_WIDTH}px;
  padding: ${SPACE.small * 1.5}px ${SPACE.small}px;
  padding-top: ${SPACE.small}px;
  background-color: ${COLOR.mediumDarkBlue};
  position: fixed;
  top: ${PAGE_HEADER_HEIGHT}px;
  width: ${SUBMENU_WIDTH}px;
  bottom: 0;
  transition: all 0.25s ease-in-out;
`

export const SubMenuTitleRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`

export const SubMenuTitleText = styled.h4``

export const CloseButtonRounded = styled(RSuiteButton)`
  height: 30px;
  width: 30px;
  background-color: black;
  padding: 0;
  border-radius: 100px;
`

export const CloseButton = styled.div`
  position: absolute;
  right: -${SPACE.small}px;
  height: 30px;
  width: 30px;
  background-color: black;
  padding: 0;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${COLOR.darkBlue};
  }
`

export const defaultMenuButtonProps = {
  size: 22
}
