import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Checkbox } from '@progress/kendo-react-inputs'
import React, { useEffect, useRef, useState } from 'react'
import { SPACE } from 'services/styles'

export default ({ data, onChange, value, defaultNoneSelectedText }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const node = useRef()
  const handleCheck = (item: DataProps) => {
    if (value.find(i => i.id === item.id)) {
      return onChange(value.filter(i => i.id !== item.id))
    } else {
      return onChange([...value, item])
    }
  }

  const handleClick = e => {
    if (
      //  @ts-ignore
      node?.current?.contains(e.target) ||
      document
        .getElementsByClassName('k-list-container')?.[0]
        ?.contains(e.target)
    ) {
    } else {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <div
        style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
      >
        <Checkbox
          style={{ marginBottom: 2 }}
          onClick={_ => handleCheck(itemProps.dataItem)}
          checked={!!value.find(i => i.id === itemProps.dataItem.id)}
        />
        <span style={{ marginLeft: SPACE.tiny }}>{li.props.children}</span>
      </div>
    )

    return React.cloneElement(li, li.props, itemChildren)
  }

  const valueRender = (element, value) => {
    if (!value) {
      return <span className="k-input" />
    }

    if (value.length === data.length && value.length > 0) {
      return <span className="k-input">All Selected</span>
    }

    if (data.length && value.length === 0 && defaultNoneSelectedText) {
      return <span className="k-input">{defaultNoneSelectedText}</span>
    }
    if (value.length) {
      return (
        <span className="k-input">{value.map(i => i.text).join('; ')}</span>
      )
    }
    return <span className="k-input">Select</span>
  }
  return (
    <div ref={node} onClick={_ => setIsOpen(true)}>
      <DropDownList
        textField="text"
        dataItemKey="id"
        data={data}
        value={value}
        opened={isOpen}
        valueRender={valueRender}
        itemRender={itemRender}
        style={{ width: '100%', minWidth: 200 }}
      />
    </div>
  )
}

export interface Props {
  data: DataProps[]
  onChange: (updatedData: DataProps[]) => void
  value: DataProps[]
  defaultNoneSelectedText?: string
}

export interface DataProps {
  id: string
  text: string
}
