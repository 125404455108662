export interface TrendedMetricsResponseAdditive {
  purchaseUnits: number
  purchaseValue: number
  rawCount: number
  isLowNumber: number
  key: string
  period: string
}

export enum TrendedMetricAdditiveEnum {
  PURCHASE_VALUE = 'PURCHASE_VALUE',
  PURCHASE_UNITS = 'PURCHASE_UNITS',
  SHARE_BY_VALUE = 'SHARE_BY_VALUE',
  SHARE_BY_OCCASIONS = 'SHARE_BY_OCCASIONS'
}
