import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn as DataColumn } from '@progress/kendo-react-grid'
import ChartAdditionalSelectorsWrapper from 'components/charts/ChartAdditionalSelectorsWrapper'
import ChartWrapper from 'components/charts/ChartWrapper'
import DataGridSortable from 'components/charts/DataGridSortable'
import ReportParameterSelector from 'components/charts/ReportParameterSelector'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import DropdownCheckbox, {
  DataProps
} from 'components/elements/DropdownCheckbox'
import LinearLoader from 'components/Loader/LinearLoader'
import ReportPage from 'components/page/ReportPage'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { startCase } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getNonAdditiveTrendedMetricsApi } from 'services/api/requests/reports/trendedMetricsNonAdditive'
import { toStartCase } from 'services/helpers/canonicalization'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import objectToCamelCase from 'services/helpers/objectToCamelCase'
import { periodListToText, periodToText } from 'services/helpers/toPeriod'
import { SPACE } from 'services/styles'
import formatData from './formatData'
import { MetricRow } from './style'
import {
  TrendedMetricNonAdditiveEnum,
  TrendedMetricsResponseNonAdditive
} from './types'

const generateInitialState = (
  nonAdditiveEnum: TrendedMetricNonAdditiveEnum
) => ({
  text: startCase(nonAdditiveEnum.toLowerCase()),
  id: nonAdditiveEnum
})

export const SubComponent = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  const [rawData, setRawData] = useState<TrendedMetricsResponseNonAdditive[]>(
    []
  )
  const [leftMetrics, setLeftMetrics] = useState<DataProps[]>([
    generateInitialState(TrendedMetricNonAdditiveEnum.BUYERS)
  ])

  const [rightMetrics, setRightMetrics] = useState<DataProps[]>([])

  const [byGroup, setByGroup] = useState({
    id: null,
    text: null
  })

  const { isLoading, setIsLoading } = useContext(ReportContext)

  const params = useSelector(ChartSelector.chartQuerySelector)

  const isCustomGroupOpen = useSelector(ChartSelector.isCustomGroupOpen)

  const uniqueGroups = params.reduce((acc, param) => [...acc, param.name], [])

  useEffect(() => {
    if (isCustomGroupOpen && !byGroup.id) {
      const group = uniqueGroups[0]
      setByGroup({ id: group, text: group })
    }
  }, [rawData])

  const handleLoad = async () => {
    setIsLoading(true)
    const response = await getNonAdditiveTrendedMetricsApi(params)
    const processedResponse = Object.entries<any[]>(response).reduce(
      (combinedAcc: object[], [key, value]) => {
        const perKey = value.reduce(
          (acc, data) => [...acc, { ...data, key }],
          []
        )
        return [...combinedAcc, ...perKey]
      },
      []
    )
    setRawData(processedResponse.map(objectToCamelCase))
    setIsLoading(false)
  }

  // TODO - simplify logic
  let filteredData = rawData.filter(data => data.key === byGroup?.id)

  if (filteredData.length === 0) {
    filteredData = rawData.filter(data => data.key === 'Default')
  }

  const leftData = leftMetrics.map(metric =>
    formatData(filteredData, metric.id)
  )
  const rightData = rightMetrics.map(metric =>
    formatData(filteredData, metric.id)
  )

  const categories = leftData?.[0]?.map(i => i.period) || []

  return (
    <>
      <LinearLoader isLoading={isLoading} />
      <ReportParameterSelector onComplete={handleLoad} />
      <ChartWrapper isLoading={isLoading}>
        <ChartAdditionalSelectorsWrapper>
          <MetricRow>
            {isCustomGroupOpen && (
              <DropDownList
                data={uniqueGroups.map(i => ({
                  id: i,
                  text: i
                }))}
                textField="text"
                dataItemKey="id"
                value={byGroup}
                onChange={i => setByGroup(i.value)}
                style={{ marginRight: SPACE.medium }}
              />
            )}
          </MetricRow>
          <MetricRow>
            <DropdownCheckbox
              data={Object.values(TrendedMetricNonAdditiveEnum).map(
                generateInitialState
              )}
              value={leftMetrics}
              onChange={setLeftMetrics}
            />
            <h3
              style={{
                marginBottom: 0,
                marginLeft: SPACE.large,
                marginRight: SPACE.large
              }}
            >
              vs
            </h3>
            <DropdownCheckbox
              data={Object.values(TrendedMetricNonAdditiveEnum).map(
                generateInitialState
              )}
              value={rightMetrics}
              onChange={setRightMetrics}
            />
          </MetricRow>
        </ChartAdditionalSelectorsWrapper>
        <div ref={chartRef}>
          <Chart transitions={false}>
            <ChartArea background={'transparent'} />
            <ChartTooltip shared={true} />
            {/* <ChartTitle */}
            {/*  text={`${toStartCase(leftMetric.id)} vs ${toStartCase( */}
            {/*    rightMetric.id */}
            {/*  )}`} */}
            {/* /> */}
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                title={{ text: 'Period' }}
                categories={periodListToText(categories)}
                axisCrossingValue={[0, 100]}
              />
            </ChartCategoryAxis>
            <ChartSeries>
              {leftData.map(leftMetric => {
                return (
                  <ChartSeriesItem
                    type={'column'}
                    data={leftMetric.map(i => i.value)}
                    name={toStartCase(leftMetric?.[0]?.key || '')}
                    axis={'left axis'}
                  />
                )
              })}
              {rightData.map(rightMetric => {
                return (
                  <ChartSeriesItem
                    type={'line'}
                    data={rightMetric.map(i => i.value)}
                    name={toStartCase(rightMetric?.[0]?.key || '')}
                    axis={'right axis'}
                  />
                )
              })}
            </ChartSeries>
            <ChartValueAxis>
              {leftMetrics.length > 0 && (
                <ChartValueAxisItem name={'left axis'} />
              )}
              {rightMetrics.length > 0 && (
                <ChartValueAxisItem name={'right axis'} />
              )}
            </ChartValueAxis>
          </Chart>
        </div>
        <MemoizedRenderTable data={rawData} dataTableRef={tableRef} />

        {/* <MemoizedRenderTable data={rawData} dataTableRef={tableRef} /> */}
        <SampleSizeWarningText />
      </ChartWrapper>
    </>
  )
})

const RenderDataTable = ({ data, dataTableRef }) => {
  const dataGridData = data.map(i => ({
    ...i,
    canonicalPeriod: periodToText(i.period),
    key: changeTextIfLowNumber(i.key, i.isLowNumber)
  }))
  return (
    <ExcelExport data={dataGridData} ref={dataTableRef}>
      <DataGridSortable data={dataGridData}>
        <DataColumn field="canonicalPeriod" title="Period" sortable />
        <DataColumn field="key" title="Groups" />
        <DataColumn
          field="buyers"
          title="No. of Buyers"
          format="{0:n0}"
          sortable
        />
        <DataColumn
          field="penetration"
          title="Penetration"
          format="{0:n3}"
          sortable
        />
        <DataColumn
          field="pricePerPack"
          title="Price per Pack"
          sortable
          format="{0:c}"
        />
        <DataColumn
          field="shareByValue"
          title="Share of Category (By Value)"
          format="{0:n2}"
        />
        <DataColumn
          field="shareByOccasions"
          title="Share of Category (By Occasions)"
          format="{0:n2}"
        />
        <DataColumn
          field="purchaseValue"
          title="Purchase Value (Indexed)"
          format="{0:n0}"
        />
        <DataColumn
          field="purchaseUnits"
          title="Purchase Units (Indexed)"
          format="{0:n0}"
        />
      </DataGridSortable>
    </ExcelExport>
  )
}
const MemoizedRenderTable = React.memo(RenderDataTable)
export default () => (
  <ReportPage title="Trended Metrics (Non-Additive)" component={SubComponent} />
)
