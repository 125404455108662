import { camelCase } from 'lodash'

const objectToCamelCase = <T>(obj: T): T => {
  let key
  const keys = Object.keys(obj)
  let n = keys.length
  const newobj: any = {}
  while (n--) {
    key = keys[n]
    //  @ts-ignore
    if (Array.isArray(obj[key])) {
      //  @ts-ignore
      newobj[camelCase(key)] = obj[key].map(objectToCamelCase)
    } else {
      //  @ts-ignore
      newobj[camelCase(key)] = obj[key]
    }
  }
  return newobj
}

export default objectToCamelCase
