import {
  ButtonRow,
  ButtonWithIcon,
  HoverDiv,
  OuterWrapper,
  Title,
  Wrapper
} from 'components/page/ReportPage/style'
import { IReportMetadataState } from 'components/page/ReportPage/types'
import React from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'rsuite'
import { SPACE } from 'services/styles'

export default ({
  title,
  dataSource,
  handleSaveClick,
  handleSaveAsNew,
  canSave,
  isCustom,
  handleRenameClick,
  handleCsvClick,
  handleExcelClick,
  handlePDFClick,
  resetState,
  handlePNGClick,
  handleDeleteClick,
  reportMetadata
}: Props) => {
  const renderTitle = () => {
    if (isCustom) {
      return (
        <HoverDiv onClick={handleRenameClick}>
          <Title>{title}</Title>
          <Icon
            icon="pencil"
            style={{ fontSize: 20, marginLeft: SPACE.small }}
          />
        </HoverDiv>
      )
    }
    return <Title>{title}</Title>
  }

  const renderSubtitle = () => {
    return (
      <>
        from{' '}
        <Link
          to={{ pathname: reportMetadata.originalUrl }}
          onClick={resetState}
        >
          {reportMetadata.originalReportName}
        </Link>
      </>
    )
  }
  return (
    <OuterWrapper>
      <Wrapper>
        {renderTitle()}
        <ButtonRow>
          <ButtonWithIcon
            appearance="subtle"
            onClick={handleSaveClick}
            disabled={!canSave}
          >
            <Icon
              icon="save"
              style={{ fontSize: 18, marginRight: SPACE.tiny }}
            />
            Save
          </ButtonWithIcon>
          <ButtonWithIcon onClick={handleSaveAsNew} appearance="subtle">
            <Icon
              icon="copy"
              style={{ fontSize: 18, marginRight: SPACE.tiny }}
            />
            Save as New
          </ButtonWithIcon>
          <Dropdown
            noCaret
            trigger="hover"
            icon={<Icon icon="more" style={{ marginRight: 0 }} />}
            placement="bottomEnd"
            style={{ zIndex: 99999 }}
          >
            {isCustom ? (
              <Dropdown.Item
                icon={<Icon icon="trash" />}
                onClick={handleDeleteClick}
                style={{ zIndex: 99999 }}
              >
                Delete
              </Dropdown.Item>
            ) : null}

            <Dropdown.Item
              icon={<Icon icon="file-image-o" />}
              onClick={handlePNGClick}
              style={{ zIndex: 99999 }}
            >
              Export PNG
            </Dropdown.Item>
            {/* <Dropdown.Item */}
            {/*  icon={<Icon icon="file-pdf-o"/>} */}
            {/*  onClick={handlePDFClick} */}
            {/*  style={{ zIndex: 99999 }} */}
            {/* > */}
            {/*  Export PDF */}
            {/* </Dropdown.Item> */}
            <Dropdown.Item
              icon={<Icon icon="file-excel-o" />}
              onClick={handleExcelClick}
              style={{ zIndex: 99999 }}
            >
              Export to Excel
            </Dropdown.Item>
            <CSVLink
              data={dataSource ?? ''}
              filename={'table.csv'}
              style={{ textDecoration: 'none' }}
            >
              <Dropdown.Item
                icon={<Icon icon="table" />}
                onClick={handleCsvClick}
                style={{ zIndex: 99999 }}
              >
                Export to CSV
              </Dropdown.Item>
            </CSVLink>
          </Dropdown>
        </ButtonRow>
      </Wrapper>
      {isCustom && renderSubtitle()}
    </OuterWrapper>
  )
}

interface Props {
  title: string
  dataSource?: any
  handleSaveClick: () => void
  handleSaveAsNew: () => void
  canSave: boolean
  isCustom: boolean
  handleRenameClick: () => void
  handleCsvClick: () => void
  handleExcelClick: () => void
  handlePNGClick: () => void
  handleDeleteClick: () => void
  handlePDFClick: () => void
  resetState: () => void
  reportMetadata: IReportMetadataState
}
