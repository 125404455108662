import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-top: -${SPACE.tiny}px;
  margin-right: -${SPACE.tiny}px;
  margin-bottom: ${SPACE.small}px;
`
