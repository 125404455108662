import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout'
import { COLOR } from 'services/styles'

export default ({ nodeData }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>{nodeData.name}</CardTitle>
        <CardTitle
          style={{
            color: nodeData?.value?.includes('-') ? COLOR.danger : COLOR.success
          }}
        >
          {nodeData.value}
        </CardTitle>
      </CardBody>
    </Card>
  )
}
