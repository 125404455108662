export enum DemographicsEnum {
  TOTAL_BUYERS = 'totalBuyers',
  TOTAL_SPEND = 'totalSpend',
  TOTAL_UNITS = 'totalUnits'
}

export const incomeMap = {
  1: 'Low',
  2: 'Mid',
  3: 'High'
}

export const householdSize = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5+'
}
