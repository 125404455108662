import { PAGE_HEADER_HEIGHT } from 'components/page/Sidebar/style'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const PageHeaderWrapper = styled.div`
  top: 0px;
  height: ${PAGE_HEADER_HEIGHT}px;
  z-index: 1040;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: ${COLOR.darkBlue};
  flex-direction: row;
  justify-content: space-between;
`

const ICON_SIZE = 45

export const UserIconWrapper = styled.div`
  font-size: 20px;
  background-color: ${COLOR.lightDarkBlue};
  border-radius: 9999px;
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const UserIconText = styled.div``

export const DropdownItem = styled.div`
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 120px;
  padding: ${SPACE.tiny}px ${SPACE.small}px;
`
