import { Loader } from '@progress/kendo-react-indicators'
import { LoaderWrapper } from 'components/charts/ChartWrapper/style'

import React from 'react'
import { Panel } from 'rsuite'
import { SPACE } from 'services/styles'

export default (props: any) => {
  return (
    <Panel
      bordered
      shaded
      style={{
        marginTop: SPACE.small,
        marginBottom: SPACE.small,
        position: 'relative'
      }}
    >
      {props.isLoading && (
        <LoaderWrapper>
          <Loader type="infinite-spinner" size="large" />
        </LoaderWrapper>
      )}
      {props.children}
    </Panel>
  )
}
