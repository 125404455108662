import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export const InnerWrapper = styled.div`
  background-color: ${COLOR.black};
  padding: ${SPACE.large}px ${SPACE.small}px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
