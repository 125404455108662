import { Tooltip } from '@progress/kendo-react-tooltip'
import {
  ButtonStyledWrapper,
  ButtonWrapper,
  TextWidth,
  Wrapper
} from 'components/charts/CustomGroups/GroupRow/style'
import React, { useState } from 'react'
import { FaPencilAlt, FaTimes } from 'react-icons/fa'

export default ({
  onHideClick,
  onRenameClick,
  isHidden,
  hasButtons,
  onDeleteClick,
  text
}: Props) => {
  const [isHover, setIsHover] = useState(false)
  const renderButtons = () => {
    if (!hasButtons) return null
    return (
      <Tooltip openDelay={0.1} position="top" anchorElement="target">
        <ButtonWrapper>
          {/* <ButtonStyledWrapper onClick={onHideClick} */}
          {/*  title={isHidden ? 'Show Group' : 'Hide Group'}> */}
          {/*  {isHidden ? <FaEyeSlash/> : */}
          {/*    <FaEye/>} */}
          {/* </ButtonStyledWrapper> */}
          <ButtonStyledWrapper onClick={onRenameClick} title="Edit Group">
            <FaPencilAlt />
          </ButtonStyledWrapper>

          <ButtonStyledWrapper onClick={onDeleteClick} title="Delete Group">
            <FaTimes />
          </ButtonStyledWrapper>
        </ButtonWrapper>
      </Tooltip>
    )
  }

  return (
    <Wrapper>
      <TextWidth style={{ textDecoration: isHidden ? 'line-through' : 'none' }}>
        {text}
      </TextWidth>
      {renderButtons()}
    </Wrapper>
  )
}

interface Props {
  isHidden?: boolean
  onRenameClick?: (any: any) => void
  onHideClick?: (any: any) => void
  onDeleteClick?: (any: any) => void
  hasButtons: boolean
  text: string
}
