import { Button } from '@progress/kendo-react-buttons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'
import { SPACE } from 'services/styles'

export default ({ onCancel, onOk, groupName }: Props) => {
  return (
    <Dialog
      title={'Delete Group'}
      onClose={onCancel}
      width={300}
      height={'auto'}
    >
      <p style={{ marginBottom: SPACE.tiny, height: 20 }}>
        Are you sure you want to delete "{groupName}"?{' '}
      </p>
      <div style={{ marginBottom: SPACE.small }} />
      <DialogActionsBar>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActionsBar>
    </Dialog>
  )
}

interface Props {
  onCancel: () => void
  onOk: () => void
  groupName: string
}
