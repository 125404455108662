import { TagWrapper, TagLabel, TagText } from './style'
import { productToObjectType } from 'components/charts/SelectionTag/helpers'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React from 'react'

const Tag = ({ text }: { text: string }) => {
  const { cleanText, color, label } = productToObjectType(text)
  if (!color) {
    return (
      <TagWrapper>
        <TagText>{cleanText}</TagText>
      </TagWrapper>
    )
  }
  return (
    <TagWrapper>
      <TagLabel style={{ backgroundColor: color }}>{label}</TagLabel>
      <TagText>{cleanText}</TagText>
    </TagWrapper>
  )
}

export interface Props {
  text: string
}

export default Tag
