import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const GraphTitle = styled.div`
  text-align: center;
  padding-left: 25px;
  max-height: 15px;
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: ${SPACE.medium}px;
`

export const MetricRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
