import { config } from 'dotenv'

config()

export interface Config {
  firebaseConfig: FirebaseConfig
  dashboardBaseUrl: string
  environment: string
  sentryDsn?: string
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export enum ENVIRONMENT {
  Production = 'PRODUCTION',
  Next = 'NEXT',
  Staging = 'STAGING',
  Development = 'development'
}

const baseConfig = {
  environment: process.env.NODE_ENV
}

const getConfig = (environment: string | undefined): Config => {
  if (environment === ENVIRONMENT.Production) {
    return {
      firebaseConfig: {
        apiKey: 'AIzaSyAi59eOxNbVQGH_P_3W4OF-PE6zWxUh5Fc',
        authDomain: 'alcohol-dashboard-prod.firebaseapp.com',
        projectId: 'alcohol-dashboard-prod',
        storageBucket: 'alcohol-dashboard-prod.appspot.com',
        messagingSenderId: '1019511963807',
        appId: '1:1019511963807:web:e1066007cf8c06f53122aa',
        measurementId: 'G-JCD96EL4R6'
      },
      sentryDsn:
        'https://0b8540ddece64435b481e24d0891b822@o78584.ingest.sentry.io/5745206',
      dashboardBaseUrl: 'https://api.au.unocart.com/dashboard-service',
      environment: ENVIRONMENT.Production,
      ...baseConfig
    }
  } else if (environment === ENVIRONMENT.Next) {
    return {
      firebaseConfig: {
        apiKey: 'AIzaSyA4798T3QH6ZCPbF5ZptS81f1mDb6BzOn8',
        authDomain: 'alcohol-dashboard-next.firebaseapp.com',
        projectId: 'alcohol-dashboard-next',
        storageBucket: 'alcohol-dashboard-next.appspot.com',
        messagingSenderId: '156718646659',
        appId: '1:156718646659:web:81b6a76c207dff79dca2b6',
        measurementId: 'G-7ZMGGTEBTK'
      },
      sentryDsn:
        'https://dbab3a8f4bd34398b27542637b152310@o78584.ingest.sentry.io/5745207',
      dashboardBaseUrl: 'https://next.api.au.unocart.com/dashboard-service',
      environment: ENVIRONMENT.Next,
      ...baseConfig
    }
  }
  return {
    firebaseConfig: {
      apiKey: 'AIzaSyChBakRZFFtJ3WTa0-xICwEgKiAbFBVnO8',
      authDomain: 'alcohol-dashboard-staging.firebaseapp.com',
      projectId: 'alcohol-dashboard-staging',
      storageBucket: 'alcohol-dashboard-staging.appspot.com',
      messagingSenderId: '510516734584',
      appId: '1:510516734584:web:aea9281b9a25d9ee051b8d',
      measurementId: 'G-W1GDBJVP0B'
    },
    dashboardBaseUrl: 'https://staging.api.au.unocart.com/dashboard-service',
    environment: ENVIRONMENT.Staging,
    ...baseConfig
  }
}

const environmentConfig = getConfig(process.env.REACT_APP_ENV)

export default environmentConfig
