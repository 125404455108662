const cleanBody = (text: string[]) => {
  const dedupe = Array.from(new Set(text))
  const removeEveryPrefix = dedupe.map(i => i.replace(/CAT|PB|SB|SSB|\|/g, ''))
  return removeEveryPrefix.map(i => i.replace(/\s\/\s/g, '/'))
}

// not sure about this function
export const cleanProductQuery = (selectedList: string[]): string[] => {
  return selectedList.map(i => {
    const removedBrackets = i.replace(/[\[\]]/g, '')
    const replaceTotal = removedBrackets.replace('TOTAL', '')
    return replaceTotal
      .replace('SSB|', '')
      .replace('SB|', '')
      .replace('CAT|', '')
      .replace('PB|', '')
      .trim()
  })
}

export default cleanBody
