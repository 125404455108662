import axios from 'axios'
import config from 'config'
import pathToApi from 'services/helpers/pathToApi'

export const getNonAdditiveTrendedMetricsApi = async (props: any[]) => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.post(
    url('/query/reports/trended-metrics-non-additive'),
    props
  )
  return data
}
