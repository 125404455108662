import { UserActionTypes, UserState } from 'data/user/types'
import produce from 'immer'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

const initialUserDataState: UserState = {
  email: undefined,
  isFirebaseReady: false
}

const userData = handleActions<UserState>(
  {
    [UserActionTypes.SET_USER]: (state, { payload }) => {
      return produce<UserState>(state, draftState => {
        draftState.email = payload.email
      })
    },
    [UserActionTypes.SET_FIREBASE_READY]: (state, { payload }) => {
      return produce<UserState>(state, draftState => {
        draftState.isFirebaseReady = true
      })
    }
  },
  initialUserDataState
)

const reducer = combineReducers<CombinedUserState>({
  userData
})

export interface CombinedUserState {
  readonly userData: UserState
}

export default reducer
