import { Button } from 'rsuite'
import { COLOR, SPACE } from 'services/styles'
import styled from 'styled-components'

export const PageContent = styled.div`
  padding: ${SPACE.medium}px;
  padding-top: ${SPACE.small}px;
  background-color: ${COLOR.lightDarkBlue};
  min-height: 100vh;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

export const OuterWrapper = styled.div`
  margin-top: ${SPACE.tiny}px;
  margin-bottom: ${SPACE.medium}px;
`
export const Title = styled.h3`
  color: ${COLOR.white};
  font-size: 35px;
`

export const ButtonRow = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const ButtonWithIcon = styled(Button)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 15px;
`

export const HoverDiv = styled.div`
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
`
