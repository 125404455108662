import { DropDownList } from '@progress/kendo-react-dropdowns'
import { ChartActions } from 'data/actions'
import { ChartSelector } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const periods = useSelector(ChartSelector.relativePeriodList)
  return (
    <DropDownList
      data={periods.relativePeriods}
      value={periods.selected}
      onChange={i => dispatch(ChartActions.setSelectedPeriod(i.target.value))}
      textField="text"
      dataItemKey="id"
      style={{ width: '100%', minWidth: 200 }}
    />
  )
}
