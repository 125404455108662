import { BORDER_RADIUS, SPACE } from 'services/styles'
import styled from 'styled-components'

export const GroupingRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const GroupingRowContentRowUnClickable = styled.div`
  width: 100px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const GroupingRowContentRowClickable = styled(
  GroupingRowContentRowUnClickable
)`
  width: 200px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const GroupingRowContentRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 47px;
  padding: ${SPACE.tiny * 0.2}px ${SPACE.small}px;
  margin: ${SPACE.tiny}px ${SPACE.tiny}px;
  align-items: center;
  border-radius: ${BORDER_RADIUS.standard}px;
`
