import { Input } from '@progress/kendo-react-inputs'
import { SPACE } from 'services/styles'
import styled from 'styled-components'

export const ColumnWrapper = styled.div``

export const SectionTitle = styled.p`
  margin-bottom: ${SPACE.tiny}px;
  font-size: 18px;
`

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const GroupName = styled(Input)`
  width: 100% !important;
  font-size: 16px;
`
