import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for
import createSagaMiddleware from 'redux-saga'
import reduxThunk from 'redux-thunk'
import Reactotron from './reactotron'
import rootReducer from './reducers'
import * as sagas from './sagas' // web
const blacklist = ['user', 'dialog', 'chart']
const persistConfig = {
  key: 'root',
  storage,
  blacklist
}

export const initSagas = (sagaMiddleware: any) => {
  Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware))
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(reduxThunk, sagaMiddleware),
    Reactotron.createEnhancer()
  )
)
initSagas(sagaMiddleware)
const persistor = persistStore(store)

export { store, persistor, sagaMiddleware }
