import {
  IOrgChart,
  PurchaseDriversResponse
} from 'pages/reports/reports/purchaseDrivers/types'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'

export default ({
  target,
  benchmark
}: {
  target?: PurchaseDriversResponse
  benchmark?: PurchaseDriversResponse
}): IOrgChart => {
  //  @ts-ignore
  if (!target || !benchmark) return {}
  const purchaseValueChange =
    ((target.purchaseValue / benchmark.purchaseValue - 1) * 100).toFixed(2) +
    '%'
  const purchaseUnitChange =
    ((target.purchaseUnits / benchmark.purchaseUnits - 1) * 100).toFixed(2) +
    '%'
  const buyingHouseholdsChange =
    ((target.buyingHouseholds / benchmark.buyingHouseholds - 1) * 100).toFixed(
      2
    ) + '%'
  const occassionsChange =
    (
      (target.purchaseOccasions / benchmark.purchaseOccasions - 1) *
      100
    ).toFixed(2) + '%'
  const unitPerOccasion =
    ((target.unitPerOccasion / benchmark.unitPerOccasion - 1) * 100).toFixed(
      2
    ) + '%'
  const pricePerUnit =
    ((target.pricePerUnit / benchmark.pricePerUnit - 1) * 100).toFixed(2) + '%'

  const isLowNumber = target.isLowNumber || benchmark.isLowNumber
  return {
    id: 'totalValue',
    name: changeTextIfLowNumber('Total Value', isLowNumber),
    value: purchaseValueChange,
    children: [
      {
        id: 'units',
        name: changeTextIfLowNumber('Units', isLowNumber),
        value: purchaseUnitChange,
        children: [
          {
            id: 'buyers',
            name: changeTextIfLowNumber('Buyers', isLowNumber),
            value: buyingHouseholdsChange
          },
          {
            id: 'occasions',
            name: changeTextIfLowNumber('Occasions', isLowNumber),
            value: occassionsChange,
            children: [
              {
                id: 'unitPerOccasion',
                name: changeTextIfLowNumber('Unit Per Occasion', isLowNumber),
                value: unitPerOccasion
              }
            ]
          }
        ]
      },
      {
        id: 'pricePerUnit',
        name: changeTextIfLowNumber('Price Per Unit', isLowNumber),
        value: pricePerUnit
      }
    ]
  }
}
