export interface TreeData {
  value: string
  label: string
  children?: TreeData[]
  disabled?: boolean
}
export interface RawTreeData {
  id: string
  children: RawTreeData[]
  label: string
}

export const renderTreeRecursive = (data: RawTreeData): TreeData => {
  const prefixedValue = data.id
  const cleanLabel = data.label
  if (data?.children?.length && cleanLabel.length) {
    return {
      value: prefixedValue,
      label: cleanLabel,
      children: data.children
        .filter(children => !!children && !!children.label)
        .map(renderTreeRecursive)
    }
  }
  return { value: prefixedValue, label: cleanLabel }
}
