import { Button } from '@progress/kendo-react-buttons'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import ChartAdditionalSelectorsWrapper from 'components/charts/ChartAdditionalSelectorsWrapper'
import ChartWrapper from 'components/charts/ChartWrapper'
import ReportParameterSelector from 'components/charts/ReportParameterSelector'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import ReportPage from 'components/page/ReportPage'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { startCase } from 'lodash'
import {
  paramsToPeriodsMatrix,
  separator
} from 'pages/reports/reports/common/helpers'
import { ChartGroup } from 'pages/reports/reports/demographics/chart'
import { DemographicsEnum } from 'pages/reports/reports/demographics/types'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Grid, Row } from 'rsuite'
import { getDemographicsApi } from 'services/api/requests/reports/demographics'
import objectToCamelCase from 'services/helpers/objectToCamelCase'
import { SPACE } from 'services/styles'

import { MetricRow } from './style'
import MemoizedTable from './table'

export enum ChartTypes {
  HOUSEHOLD_SIZE = 'householdSize',
  INCOME = 'income',
  AGE_GROUP = 'ageGroup'
}

const demographicsEnumSelector = Object.values(DemographicsEnum).map(i => ({
  id: i,
  text: startCase(i)
}))

export const SubComponent = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [combinedRawData, setCombinedRawData] = useState({})

  const [shouldShowIndex, setShouldShowIndex] = useState(true)

  const [groupCombinations, setGroupCombinations] = useState<{
    timePeriods: any[]
    groupNames: any[]
  }>({ timePeriods: [], groupNames: [] })

  const [shareBy, setShareBy] = useState({
    text: startCase(DemographicsEnum.TOTAL_BUYERS),
    id: DemographicsEnum.TOTAL_BUYERS
  })

  const [byGroup, setByGroup] = useState({ text: null, id: null })

  const [byPeriod, setByPeriod] = useState({ text: null, id: null })

  const [hiddenIndices, setHiddenIndices] = useState({
    [ChartTypes.HOUSEHOLD_SIZE]: [],
    [ChartTypes.INCOME]: [],
    [ChartTypes.AGE_GROUP]: []
  })

  const params = useSelector(ChartSelector.chartQuerySelector)
  const isCustomGroupOpen = useSelector(ChartSelector.isCustomGroupOpen)

  const rawDataKey = `${
    isCustomGroupOpen ? byGroup.id : 'Default'
  }${separator}${byPeriod.id}`
  const rawData = combinedRawData[rawDataKey] || []

  useEffect(() => {
    if (isCustomGroupOpen && !byGroup.id && !byPeriod.id) {
      setByGroup(groupCombinations.groupNames[0])
      setByPeriod(
        groupCombinations.timePeriods[groupCombinations.timePeriods.length - 1]
      )
    }
  }, [combinedRawData])

  const handleLoad = async () => {
    setIsLoading(true)
    const queries = paramsToPeriodsMatrix(params)
    const response = await getDemographicsApi(queries)
    const camelCaseResponse = Object.entries(response).reduce(
      (acc, [key, value]) => {
        acc[key] = objectToCamelCase(value)
        return acc
      },
      {}
    )

    const groupNames = Array.from(
      new Set(Object.keys(camelCaseResponse).map(i => i.split(separator)[0]))
    ).map(i => ({ id: i, text: i }))

    const timePeriods = Array.from(
      new Set(Object.keys(camelCaseResponse).map(i => i.split(separator)[1]))
    ).map(i => ({ id: i, text: i }))

    setGroupCombinations({ groupNames, timePeriods })
    setCombinedRawData(camelCaseResponse)
    setIsLoading(false)
  }

  const renderParameterSelectorGroup = () => {
    return (
      <ChartAdditionalSelectorsWrapper>
        <MetricRow>
          {isCustomGroupOpen && (
            <DropDownList
              data={groupCombinations.groupNames}
              textField="text"
              dataItemKey="id"
              value={byGroup}
              onChange={i => setByGroup(i.value)}
              style={{ marginRight: SPACE.tiny }}
            />
          )}
        </MetricRow>
        <MetricRow>
          <Button
            togglable={true}
            onClick={toggleGraph}
            style={{ marginRight: SPACE.small }}
          >
            {shouldShowIndex ? 'Hide Index' : 'Show Index'}
          </Button>
          <DropDownList
            data={groupCombinations.timePeriods}
            textField="text"
            dataItemKey="id"
            value={byPeriod}
            onChange={i => setByPeriod(i.value)}
            style={{ marginRight: SPACE.tiny }}
          />
          <DropDownList
            data={demographicsEnumSelector}
            textField="text"
            dataItemKey="id"
            value={shareBy}
            onChange={i => setShareBy(i.value)}
          />
        </MetricRow>
      </ChartAdditionalSelectorsWrapper>
    )
  }
  const toggleGraph = () => setShouldShowIndex(!shouldShowIndex)

  return (
    <>
      <ReportParameterSelector onComplete={handleLoad} />
      <ChartWrapper isLoading={isLoading}>
        {renderParameterSelectorGroup()}
        <div ref={chartRef}>
          <Grid fluid style={{ flex: 1 }}>
            <Row>
              <Col lg={8} md={24} sm={24} style={{ minWidth: 390 }}>
                <ChartGroup
                  shareBy={shareBy}
                  selectedChartType={ChartTypes.HOUSEHOLD_SIZE}
                  data={rawData}
                  shouldShowIndex={shouldShowIndex}
                  hiddenIndices={hiddenIndices}
                  setHiddenIndices={setHiddenIndices}
                />
              </Col>
              <Col lg={8} md={24} sm={24} style={{ minWidth: 390 }}>
                <ChartGroup
                  shareBy={shareBy}
                  selectedChartType={ChartTypes.INCOME}
                  data={rawData}
                  shouldShowIndex={shouldShowIndex}
                  hiddenIndices={hiddenIndices}
                  setHiddenIndices={setHiddenIndices}
                />
              </Col>
              <Col lg={8} md={24} sm={24} style={{ minWidth: 390 }}>
                <ChartGroup
                  shareBy={shareBy}
                  selectedChartType={ChartTypes.AGE_GROUP}
                  data={rawData}
                  shouldShowIndex={shouldShowIndex}
                  hiddenIndices={hiddenIndices}
                  setHiddenIndices={setHiddenIndices}
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <MemoizedTable data={rawData} dataTableRef={tableRef} />
        <SampleSizeWarningText />
      </ChartWrapper>
    </>
  )
})

export default () => (
  <ReportPage title="Demographics" component={SubComponent} />
)
