export interface TrendedMetricsResponseNonAdditive {
  period: string
  penetration: number
  buyers: number
  pricePerPack: number
  purchaseValue: number
  purchaseUnits: number
  shareByValue: number
  shareByOccasions: number
  key: string
}

export enum TrendedMetricNonAdditiveEnum {
  PENETRATION = 'PENETRATION',
  BUYERS = 'BUYERS',
  PRICE_PER_PACK = 'PRICE_PER_PACK',
  PURCHASE_VALUE = 'PURCHASE_VALUE',
  PURCHASE_UNITS = 'PURCHASE_UNITS',
  SHARE_BY_VALUE = 'SHARE_BY_VALUE',
  SHARE_BY_OCCASIONS = 'SHARE_BY_OCCASIONS'
}

export const TrendedMetricEnumToKey = {
  [TrendedMetricNonAdditiveEnum.PENETRATION]: 'penetration',
  [TrendedMetricNonAdditiveEnum.BUYERS]: 'buyers',
  [TrendedMetricNonAdditiveEnum.PRICE_PER_PACK]: 'pricePerPack',
  [TrendedMetricNonAdditiveEnum.PURCHASE_VALUE]: 'purchaseValue',
  [TrendedMetricNonAdditiveEnum.PURCHASE_UNITS]: 'purchaseUnits',
  [TrendedMetricNonAdditiveEnum.SHARE_BY_VALUE]: 'shareByValue',
  [TrendedMetricNonAdditiveEnum.SHARE_BY_OCCASIONS]: 'shareByOccasions'
}
