import LoginComponent from 'components/LoginComponent'
import PageLoader from 'components/page/PageLoader'
import RootMenu from 'components/page/Sidebar/RootMenu'
import SubMenu from 'components/page/Sidebar/SubMenu'
import { UserActions } from 'data/actions'
import { dialogSelector } from 'data/dialog/selectors'
import { userEmailSelector } from 'data/user/selectors'
import ReportsNavigation from 'navigation/reports'
import MainPage from 'pages/dashboard'
import SettingsPage from 'pages/settings'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Routes from 'routes'
import { SideBarContext } from './sidebarContext'

const MainNavigation: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(UserActions.appInitAction())
  }, [dispatch])

  const userEmail = useSelector(userEmailSelector)
  const dialogs = useSelector(dialogSelector)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [hasOpen, setHasOpen] = useState<boolean>(false)
  const handleIsOpen = isOpenVar => setIsOpen(isOpenVar)
  const handleHasOpen = hasOpenVar => setHasOpen(hasOpenVar)

  const renderContent = () => {
    if (!userEmail) return <LoginComponent />
    return (
      <SideBarContext.Provider
        value={{
          isOpen,
          hasOpen,
          setIsOpen: handleIsOpen,
          setHasOpen: handleHasOpen
        }}
      >
        <Switch>
          <Route exact path={Routes.dashboard()} component={MainPage} />
          <Route exact path={Routes.settings()} component={SettingsPage} />
          <ReportsNavigation />
        </Switch>
        <RootMenu />
        <SubMenu />
        <>{dialogs.length > 0 && dialogs[0]}</>
      </SideBarContext.Provider>
    )
  }
  return (
    <>
      <PageLoader />
      {renderContent()}
    </>
  )
}

export default MainNavigation
